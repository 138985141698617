import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./containers/layout";
import {
  Login,
  Calculation,
  Draft,
  Approval,
  BOM,
  Task,
  Checkcogm,
  Dashboard,
  ResetPassword,
  KSBT,
  Material,
  Routing,
  Register,
  NotFound,
  Allowance,
  Profile,
  Report,
} from "./pages";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/draft" element={<Draft />} />
            <Route path="/approval" element={<Approval />} />
            <Route path="/task" element={<Task />} />
            <Route path="/checkcogm" element={<Checkcogm />} />
            <Route path="/calculation" element={<Calculation />} />
            <Route path="/allowance" element={<Allowance />} />
            <Route path="/report" element={<Report />} />
            <Route path="/MasterData">
              <Route path="TARIF" element={<KSBT />} />
              <Route path="BOM" element={<BOM />} />
              <Route path="MaterialPrice" element={<Material />} />
              <Route path="Routing" element={<Routing />} />
            </Route>
            <Route path="profile" element={<Profile />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
