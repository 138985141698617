/* eslint-disable no-unused-vars */
import { Box } from "@mui/material";
import { DataGrid, gridClasses, GridActionsCellItem } from "@mui/x-data-grid";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataGridCustomToolbar from "../components/DataGridCustomToolbar";
import { alpha, styled } from "@mui/material/styles";
import api from "../configs/api";
import Swal from "sweetalert2";
import { updateStatusCalculation } from "../actions/data";
import { useDispatch } from "react-redux";
import ChangeCalStatus from "../components/Modals/ChangeCalStatus";
const Draft = () => {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  let initialStates = {
    id: "1",
    material: "1212",
    bom_id: "1212",
    qty_material: "0",
    uom_material: "0",
  };
  const [loading, setLoading] = useState(true);
  const [SKU, setSKU] = useState(initialStates);
  const [Calculation, setCalculation] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDialogBOM, setOpenDialogBOM] = useState(false);
  const [openDialogStatus, setOpenDialogStatus] = useState(false);
  const [openDialogAddComponent, setOpenDialogAddComponent] = useState(false);

  const closeModal = () => {
    setOpenDelete(false);
    setOpenDialogBOM(false);
    setOpenDialogStatus(false);
    setOpenDialogAddComponent(false);
  };
  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(20);
  const [order, setOrder] = useState([
    {
      column: "1",
      dir: "asc",
    },
  ]);
  const [search, setSearch] = useState({
    value: "",
  });

  const [value, setValue] = React.useState("1");

  const [data, setData] = useState([]);
  const [status, setStatus] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/calculation/draft_list",
          { page, limit, order, search },
          {
            signal: abortController.signal,
          }
        );

        if (data) {
          setData(data.data.data);
          setTotal(data.data.recordsFiltered);
          setStatus(data.status);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);

          setLoading(false);
        }
      }
    };
    fetchData();
    return () => abortController.abort();
  }, [page, limit, order, search]);

  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));
  const dataHeader = [
    "calculation_id",
    "plant",
    "sku",
    "sku_name",
    "month",
    "cost_pm",
    "cost_rm",
    "cost_lh",
    "cost_el",
    "cost_gs",
    "cost_dp",
    "cost_ot",
    "cogm",
    "status",
    "status_desc",
    "updated",
  ];
  const setDataOrder = (order) => {
    setLoading(true);
    const orders = order
      ? order
      : {
          field: "plant",
          dir: "asc",
        };
    setOrder([
      {
        column: String(dataHeader.indexOf(orders ? orders.field : "1")),
        dir: orders ? orders.sort : "asc",
      },
    ]);
  };
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
  });

  const usdPrice = {
    type: "number",
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    cellClassName: "font-tabular-nums",
  };
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<AutorenewIcon />}
          label="Set Status"
          onClick={() => {
            openDialogStat(params);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "calculation_id",
      headerName: "Calculation ID",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "updated",
      headerName: "Updated",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "plant",
      headerName: "Plant",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sku_name",
      headerName: "SKU NAME",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "month",
      headerName: "Month",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "cogm",
      headerName: "COGM",
      align: "center",
      headerAlign: "center",
      ...usdPrice,
      width: 200,
    },
    {
      field: "cost_pm",
      headerName: "Cost PM",
      width: 100,
    },
    {
      field: "cost_rm",
      headerName: "Cost RM",
      width: 100,
    },
    {
      field: "cost_lh",
      headerName: "Cost LH",
      width: 100,
    },
    {
      field: "cost_el",
      headerName: "Cost EL",
      width: 100,
    },
    {
      field: "cost_gs",
      headerName: "Cost GS",
      width: 100,
    },
    {
      field: "cost_dp",
      headerName: "Cost DP",
      width: 100,
    },
    {
      field: "cost_ot",
      headerName: "Cost OT",
      width: 100,
    },
    {
      field: "status_desc",
      headerName: "Status Desc",
      width: 100,
    },
  ];
  const openDialogStat = (e) => {
    setOpenDialogStatus(true);
    setCalculation({
      id: e.row.id,
      material: e.row.sku,
      cogm: e.row.cogm,
    });
  };
  const confirmStatus = (calculation_id, status) => {
    console.log("update", calculation_id, status);
    dispatch(updateStatusCalculation(calculation_id, status))
      .then(() => {
        closeModal();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: `Status of Calculation ${Calculation.material} & COGM: ${Calculation.cogm} Updated`,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setSearch({
              value: "",
            });
          }
        });
      })
      .catch((err) => {
        setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Failed to Update Data",
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              setSearch({
                value: "",
              });
            }
          });
          closeModal();
        }, 3000);
        setLoading(false);
      });
  };
  return (
    <div className="bg-white">
      {
        <ChangeCalStatus
          open={openDialogStatus}
          closeModal={closeModal}
          SKU={Calculation.material}
          calculation_id={Calculation.id}
          COGM={SKU.COGM}
          confirmStatus={confirmStatus}
        />
      }
      <div className="text-left pl-4 pb-4 font-bold text-3xl">
        <h2>Draft COGM</h2>
      </div>
      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Draft COGM"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1"></TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="flex flex-wrap text-justify mr-2 ml-2 pb-2">
        {/* <div className="w-full lg:w-9/12 overflow-auto"> */}
        <div className="w-full overflow-auto">
          <Box marginRight={"2px"}>
            <Box height="80vh">
              <StripedDataGrid
                // loading={isLoading || !data}
                getRowId={(row) => row.id}
                columns={columns}
                rowCount={(limit && total) || 0}
                rowsPerPageOptions={[20, 50, 100]}
                rows={data || []}
                pagination
                paginationMode="server"
                sortingMode="server"
                page={page}
                pageSize={limit}
                onPageSizeChange={(newPageSize) => (
                  setlimit(newPageSize), setLoading(true)
                )}
                sortModel={
                  order.length > 0
                    ? [
                        {
                          field: dataHeader[order[0].column],
                          sort: order[0].dir,
                        },
                      ]
                    : []
                }
                onPageChange={(newPage) => (setPage(newPage), setLoading(true))}
                onSortModelChange={(newSortModel) =>
                  setDataOrder(...newSortModel)
                }
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                components={{ Toolbar: DataGridCustomToolbar }}
                componentsProps={{
                  toolbar: { searchInput, setSearchInput, setSearch },
                }}
              />
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default Draft;
