import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
  TextField,
  Autocomplete,
} from "@mui/material";
import Swal from "sweetalert2";
import { Close } from "@mui/icons-material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import api from "../../configs/api";
import { useState } from "react";

const UpdateKSBT = (props) => {
  const [values, setValues] = useState({
    date: "",
    price: 0,
  });
  const [hasError, sethasError] = useState(false);
  const bulan = [
    {
      id: 1,
      label: "januari",
    },
    {
      id: 2,
      label: "februari",
    },
    {
      id: 3,
      label: "maret",
    },
    {
      id: 4,
      label: "april",
    },
    {
      id: 5,
      label: "mei",
    },
    {
      id: 6,
      label: "juni",
    },
    {
      id: 7,
      label: "juli",
    },
    {
      id: 8,
      label: "agustus",
    },
    {
      id: 9,
      label: "september",
    },
    {
      id: 10,
      label: "oktober",
    },
    {
      id: 11,
      label: "november",
    },
    {
      id: 12,
      label: "desember",
    },
  ];
  const updateMaterial = (event) => {
    console.log("event.target.name", event.target.name);
    if (event.target.name === "date") {
      setValues({ ...values, [event.target.name]: event.target.value });
    } else {
      setValues({
        ...values,
        [event.target.name]:
          event.target.value === ""
            ? parseInt(0)
            : parseInt(event.target.value),
      });
    }
  };
  const resetValues = () => {
    props.closeModal();
    sethasError(false);
    setValues({ date: "", price: 0 });
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const handleSubmit = (e) => {
    let abortController = new AbortController();
    let signal = abortController.signal;

    (async () => {
      await api
        .patch(
          "/api/master/update_price_ksbt",
          {
            cost_center: props.KSBT.cost_center,
            activity_type: props.KSBT.activity_type,
            date: values.date,
            price: values.price,
          },
          { signal: signal }
        )
        .then((res) => {
          console.log(res);
          Toast.fire({
            icon: "success",
            title: `Update Price: ${res.data.message} ✔️`,
          });
          setTimeout(() => {
            resetValues();

            props.refresh(props.KSBT.cost_center);
          }, 100);
        })
        .catch((err) => {
          if (!abortController.signal.aborted) {
            Toast.fire({
              icon: "error",
              title: `Update Price: ${err.response.data.message} ❌`,
            });
          }

          Toast.fire({
            icon: "error",
            title: `Update Price: ${err.response.data.message} ❌`,
          });
          console.log(err);
        });
    })();
  };

  return (
    <Dialog
      open={props.open}
      onClose={resetValues}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Update KSBT Price
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={resetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          Change this Cost Center {props.KSBT ? props.KSBT.cost_center : ""},
          Tyoe:
          {props.KSBT ? props.KSBT.activity_type : ""} Price in The fields
          below:
        </DialogContentText>

        <Box
          display="flex"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Autocomplete
            id="combo-box-demo"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={bulan}
            onChange={(event, newValue) => {
              const value = newValue ? newValue.label : "";
              updateMaterial({
                target: {
                  name: "date",
                  value: value,
                },
              });
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Bulan" />}
          />
          <TextField
            autoFocus
            onChange={(event) => updateMaterial(event)}
            margin="dense"
            value={values.price}
            id="price"
            name="price"
            sx={{ m: 1 }}
            label="Price"
            type="number"
            fullWidth
            InputProps={{
              inputProps: { min: 0 },
              // endAdornment: <InputAdornment position="end">pcs</InputAdornment>,
            }}
          />
        </Box>

        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            style={{
              borderRadius: 5,
              backgroundColor: `${
                values.date === "" || values.price === 0 ? "#ccc" : "#3f51b5"
              }}`,
              color: "#fff",
              padding: "9px 12px",
              fontSize: "12px",
              width: "100px",
              fontWeight: "bold",
              border: "none",
              marginRight: "0px",
              marginLeft: "15px",
            }}
            onClick={handleSubmit}
            endIcon={<PublishedWithChangesIcon />}
            disabled={values.date === "" || values.price === 0 ? true : false}
          >
            Update
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
export default UpdateKSBT;
