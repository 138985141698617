const getLocalRefreshToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return token?.token;
};

const getLocalAccessToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));

  return token?.token;
};
const setToken = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};
const updateLocalAccessToken = (tokens) => {
  let token = JSON.parse(localStorage.getItem("token"));
  token = tokens;
  localStorage.setItem("token", JSON.stringify(token));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const updateLocalUser = (users) => {
  let user = JSON.parse(localStorage.getItem("user"));
  user = users;
  localStorage.setItem("user", JSON.stringify(user));
};
const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("pop_status");
  localStorage.removeItem("token");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  setToken,
  updateLocalUser,
};

export default TokenService;
