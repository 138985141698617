import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import api from "../../configs/api";
import { styled } from "@mui/system";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useCallback } from "react";
import { Box } from "@mui/system";

const AddComponent = (props) => {
  const [material, setMaterial] = React.useState([
    {
      material: "",
      quantity: 0,
    },
  ]);
  const { materials } = useSelector((state) => state.Data);

  const closeAll = () => {
    props.closeModal();
    setMaterial([
      {
        material: "",
        quantity: 0,
      },
    ]);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && material !== "" && material !== null) {
    } else {
    }
  };
  const addMaterial = () => {
    setMaterial([
      ...material,
      {
        material: "",
        quantity: 0,
      },
    ]);
  };
  const updateMaterial = (index, event) => {
    if (event.target.name === "material") {
      const materials = [...material];
      materials[index][event.target.name] = event.target.value;
      setMaterial(materials);
    } else {
      const materials = [...material];
      materials[index][event.target.name] =
        event.target.value === "" ? parseInt(0) : parseInt(event.target.value);

      setMaterial(materials);
    }
  };

  const deleteMaterial = (index) => {
    if (material.length === 1) {
      setMaterial([
        {
          material: "",
          quantity: 0,
        },
      ]);
      return;
    }

    const updatedMaterials = [...material];

    console.log(
      ...updatedMaterials.slice(0, index),
      ...updatedMaterials.slice(index + 1)
    );
    setMaterial([
      ...updatedMaterials.slice(0, index),
      ...updatedMaterials.slice(index + 1),
    ]);
  };

  let data = materials.filter(
    (v, i, a) => a.findIndex((v2) => v2.material_name === v.material_name) === i
  );
  const options = data.map((option) => {
    const firstLetter = option.material_name[0].toUpperCase();

    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  React.useEffect(() => {
    // This will be called after the state is updated
    console.log("After setMaterial:", material);
  }, [material]);

  const GroupHeader = ({ children }) => (
    <div
      style={{
        position: "sticky",
        top: "-8px",
        padding: "4px 10px",
        color: "darkslategray",
        backgroundColor: "aliceblue",
      }}
    >
      {children}
    </div>
  );
  const GroupItems = styled("ul")({
    padding: 0,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const submit = () => {
    let abortController = new AbortController();
    let signal = abortController.signal;

    (async () => {
      await api
        .post(
          "/api/bom/add_component",
          {
            sku: props.SKU.sku,
            plant: props.SKU.plant,
            component: material,
          },
          { signal: signal }
        )
        .then((res) => {
          console.log(res);
          Toast.fire({
            icon: "success",
            title: `response: ${res.data.message} ✔️`,
          });
          setTimeout(() => {
            props.refresh(props.SKU.sku);
            closeAll();
          }, 1000);
        })
        .catch((err) => {
          if (!abortController.signal.aborted) {
            Toast.fire({
              icon: "error",
              title: `Failed to Upload Data: ${err.response.data.message} ❌`,
            });
          }

          Toast.fire({
            icon: "error",
            title: `Failed to Upload Data: ${err.response.data.message} ❌`,
          });
          console.log(err);
        });
    })();
  };
  return (
    <Dialog
      style={{
        height: "500px",
        marginTop: "50px",
      }}
      open={props.open}
      onClose={closeAll}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        To add a component to SKU {props.SKU.sku} ({props.SKU.plant}), please
        enter the material and quantity.
      </DialogTitle>
      <DialogContent
        sx={{
          margin: 0,
        }}
      >
        {/* <DialogContentText>
          To add a component to the BOM, please enter the component name and
          quantity.
        </DialogContentText> */}
        {material.map((item, index) => {
          return (
            <Box
              key={index}
              display="flex"
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Autocomplete
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                style={{ width: 1000, color: "#ffffff", marginTop: "5px" }}
                value={
                  options.find(
                    (opt) => opt.material === material[index]?.material
                  ) || null
                }
                onChange={(event, newValue) => {
                  const value = newValue ? newValue.material : "";
                  updateMaterial(index, {
                    target: { name: "material", value: value },
                  });
                }}
                id="grouped-demo"
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option, index) =>
                  option.material_name +
                  `, code:(${option.material})` +
                  `plant:(${option.plant})`
                }
                sx={{ width: 300 }}
                renderInput={(params) => {
                  params.inputProps.onKeyDown = handleKeyDown;
                  return (
                    <TextField {...params} label="Search Material" required />
                  );
                }}
                renderGroup={(params) => (
                  <ul key={params.key}>
                    <li>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  </ul>
                )}
              />
              <TextField
                autoFocus
                onChange={(event) => updateMaterial(index, event)}
                margin="dense"
                value={item.quantity}
                id="quantity"
                name="quantity"
                sx={{ m: 1 }}
                label="Qty"
                type="number"
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                  // endAdornment: <InputAdornment position="end">pcs</InputAdornment>,
                }}
              />
              <Button
                // disabled={isLoading}
                onClick={() => deleteMaterial(index)}
                style={{
                  borderRadius: 5,
                  backgroundColor: "red",
                  color: "#fff",
                  padding: "10px",
                  alignItems: "center",
                  width: "50%",
                  fontSize: "20px",
                  fontWeight: "bold",
                  border: "none",
                }}
                variant="outlined"
              >
                <DeleteForeverIcon style={{ fontSize: "160%" }} />
              </Button>
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginTop: "0px",
            marginBottom: "10px",
          }}
        >
          <Button
            // disabled={isLoading}
            onClick={addMaterial}
            style={{
              borderRadius: 5,
              backgroundColor: "#F7D716",
              color: "#fff",
              padding: "9px 12px",
              fontSize: "12px",
              width: "100px",
              fontWeight: "bold",
              border: "none",
              marginRight: "0px",
              marginLeft: "15px",
            }}
            variant="outlined"
            endIcon={<NoteAddIcon />}
          >
            Add
          </Button>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={closeAll}
              color="warning"
              variant="outlined"
              style={{
                marginRight: "15px",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                material.some((item) => item.material === "") ||
                material.some((item) => item.quantity === 0)
                  ? true
                  : false
              }
              onClick={submit}
              color="primary"
              variant="outlined"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default AddComponent;
