import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
// import { setupListeners } from "@reduxjs/toolkit/query";
import { ThemeProvider } from "@material-tailwind/react";
// setupListeners(store.dispatch);

const root = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  root
);
