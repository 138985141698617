import { useState, useRef } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";

import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import "react-spinner-animated/dist/index.css";
import { getMaterials, update_routing } from "../../actions/data";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import {
  Button,
  createTheme,
  Box,
  Tooltip,
  Autocomplete,
  ThemeProvider,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import { useEffect, useMemo, useCallback } from "react";
import { set } from "react-hook-form";

const UpdateRouting = (props) => {
  console.log(props.data);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const dispatch = useDispatch();
  const globalTheme = useTheme();
  const [loading, setLoading] = useState(false);
  const [valuess, setValues] = useState(props.data);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
  };

  const { message } = useSelector((state) => state.Message);

  useEffect(
    (prev) => {
      setValues(() => props.data);
    },
    [props.data]
  );

  const handleChange = (e) => {
    if (
      (e.target.value === "" ||
        e.target.value === null ||
        e.target.value < 0) &&
      e.target.type === "number"
    ) {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: parseFloat(0) });
    } else if (e.target.type === "text") {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: value });
    } else {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: parseFloat(value) });
    }
  };
  const resetValues = () => {
    setValues({
      id: "",
      cost_center: "",
      short_description: "",
      plant: "",
      material: "",
      material_name: "",
      base_quantity: 0,
      ZAP_DP_acttyp: "",
      ZAP_DP_stdval: 0,
      ZAP_DP_unit: "",
      ZAP_EL_acttyp: "",
      ZAP_EL_stdval: 0,
      ZAP_EL_unit: "",
      ZAP_LH_acttyp: "",
      ZAP_LH_stdval: 0,
      ZAP_LH_unit: "",
      ZAP_OT_acttyp: "",
      ZAP_OT_stdval: 0,
      ZAP_OT_unit: "",
      kwh: 0,
      no_mc_mmbtu: 0,
      noempl: 0,
    });
    props.closeModal();
  };

  //Create BOM
  const submitData = (e) => {
    e.preventDefault();

    const data = {
      routing_id: valuess.id,
      cost_center: valuess.cost_center,
      plant: valuess.plant,
      base_quantity: valuess.base_quantity,
      kwH: valuess.kwh,
      no_mc_mmbtu: valuess.no_mc_mmbtu,
      noempl: valuess.noempl,
      ZAP_DP: {
        acttyp: valuess.ZAP_DP_acttyp,
        stdval: valuess.ZAP_DP_stdval,
        unit: valuess.ZAP_DP_unit,
      },
      ZAP_OT: {
        acttyp: valuess.ZAP_OT_acttyp,
        stdval: valuess.ZAP_OT_stdval,
        unit: valuess.ZAP_OT_unit,
      },
      ZAP_LH: {
        acttyp: valuess.ZAP_LH_acttyp,
        stdval: valuess.ZAP_LH_stdval,
        unit: valuess.ZAP_LH_unit,
      },
    };

    dispatch(update_routing(data))
      .then(() => {
        Toast.fire({
          icon: "success",
          title: `Success Updated Routing Value✔️`,
        });

        resetValues();
        props.refreshData();
      })
      .catch((err) => {
        resetValues();
        Toast.fire({
          icon: "error",
          title: `Failed to Update Routing Value ❌`,
        });
      });
  };

  return (
    <Dialog
      style={{}}
      open={props.open}
      onClose={resetValues}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          position: "sticky",
        }}
      >
        Change Routing Data
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={resetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {/* <form onSubmit={submitData}> */}
      <DialogContent dividers>
        <DialogContentText>
          Edit Routing value for Details {valuess.cost_center}:
        </DialogContentText>
        <TextField
          margin="normal"
          variant="standard"
          label="plant"
          name="plant"
          sx={{
            marginRight: 2,
          }}
          value={valuess.plant}
          onChange={handleChange}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="Base Quantity"
          name="base_quantity"
          sx={{
            marginRight: 2,
          }}
          value={valuess.base_quantity}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="KWH"
          name="kwh"
          sx={{
            marginRight: 2,
          }}
          value={valuess.kwh}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="NO MC MMBTU"
          name="no_mc_mmbtu"
          sx={{
            marginRight: 2,
          }}
          value={valuess.no_mc_mmbtu}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="Employee"
          name="noempl"
          sx={{
            marginRight: 2,
          }}
          value={valuess.noempl}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <DialogContentText
          style={{
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          Edit Standard value for Cost Center {valuess.cost_center}:
        </DialogContentText>
        <TextField
          margin="normal"
          variant="standard"
          label="ZAP_DP (stdval)"
          name="ZAP_DP_stdval"
          sx={{
            marginRight: 2,
          }}
          value={valuess.ZAP_DP_stdval}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="ZAP_OT (stdval)"
          name="ZAP_OT_stdval"
          sx={{
            marginRight: 2,
          }}
          value={valuess.ZAP_OT_stdval}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="ZAP_LH (stdval)"
          name="ZAP_LH_stdval"
          sx={{
            marginRight: 2,
          }}
          value={valuess.ZAP_LH_stdval}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "green",
            },
          }}
          onClick={submitData}
          endIcon={<PublishedWithChangesIcon />}
        >
          Submit
        </Button>
      </DialogActions>
      {/* </form> */}
    </Dialog>
  );
};

export default UpdateRouting;
