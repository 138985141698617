/* eslint-disable jsx-a11y/anchor-is-valid */
import TableData from "../Table/TableData";
import Swal from "sweetalert2";
import MaterialReactTable from "material-react-table";
import api from "../../configs/api";
import {
  Button,
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  columnData,
  visibility,
  columnDataKSBT,
  DataKSBT,
  DataRouting,
  columnDataRouting,
} from "./columnData";
import CardItem from "./CardItem";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const CardCalculation = (props) => {
  const globalTheme = useTheme();
  const [data, setData] = useState([]);
  const [SKU, setSKU] = useState(props.SKU);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const tableTheme = useMemo(
    () =>
      createTheme({
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            "&:last-child": {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        overrides: {
          MuiTableCell: {
            root: {
              color: "grey",
              padding: "0px",
            },
          },
        },
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(235,236,236)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },

        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "red", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const calculation = (sku) => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/calculation/calculate_by_sku",
          { sku },
          { signal: abortController.signal }
        );

        if (data) {
          Toast.fire({
            icon: "success",
            title: "Calculation Success ✅",
          });
          setData(data.data);

          setTimeout(() => {
            setValue("4");
          }, 500);
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Calculation Failed ❌",
        });
        if (!abortController.signal.aborted) {
          setData([]);
        }
        setData([]);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "plant",
        header: "Plant",
        size: 20,
        enableColumnActions: false,
        enableColumnOrdering: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "material",
        header: "Material",
        size: 20,
        enableColumnActions: false,
        enableColumnOrdering: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "material_name",
        header: "Material Name",
        size: 30,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "qty",
        header: "Quantity",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        size: 30,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "uom",
        header: "UOM",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        size: 20,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setData([]);
    setValue(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  const transformed = data.map((el) => {
    let temItem = el;
    if (el.data_calculation.length < 1) {
      return temItem;
    }
    el.data_calculation.forEach((item) => {
      temItem = {
        ...temItem,
        ["pm_" + item.month]: item.pm,
        ["rm_" + item.month]: item.rm,
        ["lh_" + item.month]: item.lh,
        ["el_" + item.month]: item.el,
        ["gs_" + item.month]: item.gs,
        ["dp_" + item.month]: item.dp,
        ["ot_" + item.month]: item.ot,
        ["cogm_" + item.month]: item.cogm,
      };
    });
    delete temItem.data_calculation;
    return temItem;
  });

  const columnsCal = useMemo(() => columnData, []);

  return (
    <div className="m-2">
      {SKU.map(
        (
          {
            sku_name,
            plant,
            sku,
            status,
            base_qty,
            uom,
            component,
            ksbt,
            routing,
            valid_from,
            valid_to,
          },
          index
        ) => (
          <CardItem
            SKU_props={props.SKU}
            sku_name={sku_name}
            component={component}
            ksbt={ksbt}
            routing={routing}
            plant={plant}
            sku={sku}
            status={status}
            base_qty={base_qty}
            valid_from={valid_from}
            valid_to={valid_to}
            uom={uom}
            key={index}
            dataSKUList={props.dataSKUList}
          />
        )
      )}

      <div className="bg-white">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="BOM" value="1" />
                <Tab label="KSBT" value="2" />
                <Tab label="Routing" value="3" />
                <Tab label="Calculation" value="4" />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1">
              <div className="mt-2">
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    columns={columns}
                    data={props.component}
                    enableHiding={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableStickyHeader
                    enableClickToCopy
                    enableColumnOrdering
                    renderTopToolbarCustomActions={() => (
                      <Button
                        color="success"
                        size="medium"
                        sx={{
                          borderRadius: 2,
                          fontSize: "0.875rem",
                        }}
                        onClick={() => calculation(props.data.sku)}
                        variant="outlined"
                      >
                        Calculate
                      </Button>
                    )}
                    muiTableContainerProps={{
                      sx: { maxHeight: "500px", margin: "2px" },
                    }}
                    getRowId={(row) => row.id}
                    muiTableHeadCellProps={{
                      sx: {
                        borderRight: " solid #e0e0e0",
                        alignItems: "center",
                        // "& .Mui-TableHeadCell-Content-Labels": {
                        //   padding: "0px",
                        // },
                        "& .MuiBox-root": {
                          paddingLeft: "0px",
                        },
                        backgroundColor: "white",

                        borderTop: " solid #e0e0e0",
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        borderRight: " solid #e0e0e0",
                        borderTop: " solid #e0e0e0",
                      },
                    }}
                    muiToolbarAlertBannerProps={
                      props.component.length === 0
                        ? {
                            color: "error",
                            children: "Error loading data, No data found",
                          }
                        : undefined
                    }
                    muiSelectCheckboxProps={{
                      color: "secondary",
                    }}
                    state={{
                      density: "compact",
                    }}
                    muiTableBodyProps={{
                      sx: {
                        //stripe the rows, make odd rows a darker color
                        "& td:nth-of-type(odd)": {
                          backgroundColor: "#f5f5f5",
                        },
                      },
                    }}
                  />
                </ThemeProvider>
              </div>
            </TabPanel>
            <TabPanel value="2" style={{ padding: 2 }}>
              <TableData
                columns={columnDataKSBT}
                data={DataKSBT(props.KSBT)}
                visibilities={visibility}
              />
            </TabPanel>
            <TabPanel value="3" style={{ padding: 2 }}>
              <TableData
                columns={columnDataRouting}
                data={DataRouting(props.routing)}
                visibilities={visibility}
              />
            </TabPanel>
            <TabPanel value="4" style={{ padding: 2 }}>
              <TableData
                columns={columnsCal}
                data={transformed}
                visibilities={visibility}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
export default CardCalculation;
