import api from "../configs/api";
import { SET_STATUS } from "../actions/types";

/*GET ALL DATA*/
const getAllSKU = async (signal) => {
  const data = await api.get("/api/master/sku", { signal: signal });
  return data;
};

/* BO COMPONENT */
const getBomSku = async (sku) => {
  const data = await api.get(`/api/bom/getsku/${sku}`);
  return data;
};

/* MATERIAL COMPONENT */
const getMaterial = (page, limit, order, search, signal) => (dispatch) => {
  return api
    .post(
      "/api/master/list_material",
      { page, limit, order, search },
      { signal: signal }
    )
    .then((res) => {
      dispatch({ type: SET_STATUS, payload: res.status });
      return res;
    })
    .catch((err) => {
      dispatch({ type: SET_STATUS, payload: err.status });
      return err;
    });
};

const createMaterial = (data) => {
  return api.post("/api/master/create_material", {
    plant: data.plant,
    material: data.material,
    material_description: data.material_description,
    type: data.type,
    price: data.price,
  });
};

/* ROUTING */
const getRouting = (page, limit, order, search, signal) => (dispatch) => {
  return api
    .post(
      "/api/master/list_routing",
      { page, limit, order, search },
      { signal: signal }
    )
    .then((res) => {
      dispatch({ type: SET_STATUS, payload: res.status });
      return res;
    })
    .catch((err) => {
      dispatch({ type: SET_STATUS, payload: err.status });
      return err;
    });
};
const updateRouting = (data) => {
  console.log("data", data);
  return api.patch("/api/master/update_routing", {
    routing_id: data.routing_id,
    cost_center: data.cost_center,
    plant: data.plant,
    base_quantity: data.base_quantity,
    kwh: data.kwH,
    no_mc_mmbtu: data.no_mc_mmbtu,
    noempl: data.noempl,
    ZAP_DP: data.ZAP_DP,
    ZAP_LH: data.ZAP_LH,
    ZAP_OT: data.ZAP_OT,
  });
};
//deleteDataBOM
const deleteData = (bom_id) => {
  return api.delete("/api/bom/delete_component", { data: { bom_id } });
};

//updateDataBOM
const updateQty = (bom_id, quantity) => {
  return api.patch("/api/bom/update_component", { bom_id, quantity });
};

const updateStatus = (bom_id, status) => {
  return api.patch("/api/bom/set_status", { bom_id, status });
};

//Get Material
const getMaterialData = (plant) => {
  return api.get(`/api/master/material/${plant}`);
};

//createDataBOM
const createData = (data) => {
  return api.post("/api/bom/create_bom", data);
};

const updateStatusCalculation = (calculation_id, status) => {
  return api.patch("/api/calculation/set_status", {
    result_id: calculation_id,
    status,
  });
};

const updateAllowance = (data) => {
  return api.post("/api/master/create_waste_allowance", {
    type: data.type,
    allowance: data.allowance,
  });
};

//calculate all data
const calculateAllData = () => {
  return api.post("/api/calculation/calculate_all");
};

const dataService = {
  getAllSKU,
  calculateAllData,
  createData,
  deleteData,
  updateAllowance,
  getBomSku,
  getMaterial,
  updateStatusCalculation,
  getMaterialData,
  getRouting,
  updateQty,
  createMaterial,
  updateStatus,
  updateRouting,
};

export default dataService;
