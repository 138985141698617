import React, { useReff } from "react";
import api from "../../configs/api";
import Swal from "sweetalert2";
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Box,
  IconButton,
  TextField,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import BackupIcon from "@mui/icons-material/Backup";
// import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import { makeStyles } from "@material-ui/core/styles";
import { set } from "react-hook-form";

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  topScrollPaper: {
    marginTop: "-60px",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "50px",
  },
});

const UploadData = ({ show, handleClose, datas }) => {
  console.log(datas.length);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [activity, setActivity] = React.useState("");
  const [dateRange, setDateRange] = React.useState([]);
  const [disableDownload, setDisableDownload] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const formatDate = (date) => {
    let formattedDate = date.toLocaleDateString("en-SE", {
      timeZone: "Asia/Bangkok",
    });
    // date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    return formattedDate;
  };
  const [valuess, setValues] = React.useState({
    description: "",
    calculation_id: "",
    fromTo: "",
    sku: datas ? datas : [],
    periodFrom: "",
    periodTo: "",
  });
  const onResetValues = () => {
    setActivity("");
    setDateRange([]);
    setValues({
      description: "",
      calculation_id: "",
      fromTo: "",
      sku: datas ? datas : [],
      periodFrom: "",
      periodTo: "",
    });
    setLoading(false);
    handleClose();
  };
  const onHandleChange = (e) => {
    if (e.target) {
      if (e.target.value === "" || e.target.value === null) {
        console.log("empty");

        setValues({ ...valuess, [e.target.name]: e.target.value });
      } else if (e.target.type === "text") {
        const { name, value } = e.target;
        setValues({ ...valuess, [name]: value });
      }
    } else {
      if (e.length > 0) {
        return setDateRange([
          formatDate(e[0]),
          formatDate(e[1]) ? formatDate(e[1]) : formatDate(e[0]),
        ]);
      }
      return setDateRange([]);
    }
  };
  const submitDownload = async () => {
    console.log({
      description: valuess.description,
      calculation_id: valuess.calculation_id,
      period_from: dateRange[0],
      period_to: dateRange[1],
      sku: datas,
    });
    setLoading(true);
    await api
      .post("/api/report/detail_result_queue", {
        description: valuess.description,
        calculation_id: valuess.calculation_id,
        periodFrom: dateRange[0],
        periodTo: dateRange[1],
        sku: datas,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          Toast.fire({
            icon: "success",
            title: res.data.message,
          });
          setTimeout(() => {
            setLoading(false);
            onResetValues();
          }, 3000);
        } else {
          Toast.fire({
            icon: "error",
            title: res.data.message,
          });
          setLoading(false);
          onResetValues();
        }
      })
      .catch((err) => {
        console.log(err);
        Toast.fire({
          icon: "error",
          title: err.message,
        });
        setLoading(false);
        onResetValues();
      });
  };

  return (
    <Dialog
      PaperProps={{ style: { overflowY: "visible" } }}
      classes={{
        paperFullWidth: classes.paperFullWidth,
        dialogPaper: classes.paperFullWidth,
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      onClose={onResetValues}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogTitle id="customized-dialog-title" onClose={onResetValues}>
        Upload Data
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onResetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ overflowY: "visible" }}
        classes={{
          root: classes.dialogContentRoot,
          dialogPaper: classes.paperFullWidth,
        }}
        dividers
      >
        <DialogContentText>{`Total: ${datas.length} SKUs`}</DialogContentText>
        <TextField
          margin="normal"
          variant="standard"
          label="Description"
          name="description"
          sx={{
            marginTop: 1,
          }}
          style={{ width: "98%" }}
          value={valuess.description}
          onChange={onHandleChange}
          type="text"
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="Calculation id"
          name="calculation_id"
          sx={{}}
          style={{ width: "98%" }}
          value={valuess.calculation_id}
          onChange={onHandleChange}
          type="text"
        />

        <div className="relative w-96 mt-4">
          <Flatpickr
            margin="normal"
            className="form-input pl-9  text-slate-600 hover:text-slate-600 font-medium focus:border-slate-300 w-96"
            options={{
              mode: "range",
              static: true,

              //   monthSelectorType: "static",
              dateFormat: "M j, Y",
              // defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],

              prevArrow:
                '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
              nextArrow:
                '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
              onReady: (selectedDates, dateStr, instance) => {
                instance.element.value = dateStr.replace("to", "-");
              },
              onChange: (selectedDates, dateStr, instance) => {
                instance.element.value = dateStr.replace("to", "-");
              },
            }}
            disabled={valuess.description === "" ? true : false}
            onClose={(date) => {
              onHandleChange(date);
            }}
          />
          <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
            <svg
              className="w-4 h-4 fill-current text-slate-500 ml-3"
              viewBox="0 0 16 16"
            >
              <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
            </svg>
          </div>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "1rem 1.5rem",
        }}
      >
        <Button
          autoFocus
          onClick={onResetValues}
          color="error"
          variant="outlined"
          endIcon={<ClearIcon />}
          style={{
            marginRight: "1rem",
          }}
        >
          cancel
        </Button>
        <Button
          style={{
            marginLeft: "1rem",
          }}
          autoFocus
          onClick={(e) => {
            submitDownload();
          }}
          disabled={
            valuess.description !== "" && dateRange.length > 0 && !loading
              ? false
              : true
          }
          color="primary"
          variant="outlined"
          endIcon={<BackupIcon />}
        >
          {loading ? "Loading..." : "Upload Data"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UploadData;
