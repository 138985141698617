import { Box } from "@mui/material";
import { DataGrid, gridClasses, GridActionsCellItem } from "@mui/x-data-grid";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import React, { useState, useEffect } from "react";
import BackupIcon from "@mui/icons-material/Backup";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataGridCustomToolbar from "../components/DataGridCustomToolbar";
import { alpha, styled } from "@mui/material/styles";
import api from "../configs/api";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { UploadData } from "../components/Modals";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Report = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  let initialStates = {
    id: "1",
    material: "1212",
    bom_id: "1212",
    qty_material: "0",
    uom_material: "0",
  };
  const [loading, setLoading] = useState(true);
  const [SKU, setSKU] = useState(initialStates);
  const [excelDatas, setExcelDatas] = useState([]);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(20);
  const [order, setOrder] = useState([
    {
      column: "1",
      dir: "asc",
    },
    {
      column: "3",
      dir: "asc",
    },
  ]);
  const [search, setSearch] = useState({
    value: "",
  });

  const [value, setValue] = React.useState("1");
  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);

  // submit state
  const [excelData, setExcelData] = useState(null);

  const [data, setData] = useState([]);
  const [status, setStatus] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    const fetchData = async () => {
      console.log(order);
      try {
        const { data } = await api.post(
          "/api/report/list",
          { page, limit, order, search },
          {
            signal: abortController.signal,
          }
        );

        if (data) {
          setData(data.data.data);
          setTotal(data.data.recordsFiltered);
          setStatus(data.status);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setData([]);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);

          setLoading(false);
        }
      }
    };
    fetchData();
    return () => abortController.abort();
  }, [page, limit, order, search]);

  const dataHeader = React.useMemo(
    () => [
      "sku",
      "description",
      "calculation_id",
      "periodFrom",
      "periodTo",
      "status",
      "updated",
    ],
    []
  );
  const [orderTable, setOrderTable] = useState([
    {
      field: "sku",
      sort: "asc",
    },
  ]);
  const setDataOrder = React.useCallback(
    (order) => {
      setLoading(true);
      setOrder([
        {
          column: String(dataHeader.indexOf(order.field) + 1),
          dir: order.sort,
        },
      ]);
    },

    [dataHeader]
  );

  const refresh = async () => {
    const abortController = new AbortController();
    setLoading(true);

    try {
      const { data } = await api.post(
        "/api/report/list",
        {
          page,
          limit,
          order,
          search: {
            value: "",
          },
        },
        {
          signal: abortController.signal,
        }
      );

      if (data) {
        setData(data.data.data);
        setTotal(data.data.recordsFiltered);
        setStatus(data.status);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setData([]);
      }
    } catch (error) {
      if (!abortController.signal.aborted) {
        setData([]);

        setLoading(false);
      }
    }

    return () => abortController.abort();
  };
  const downloadExcel = (data) => {
    try {
      api
        .get(
          `https://cogm.nabatisnack.co.id:3001/api/report/download_file/${data.id}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(
            blob,
            `${
              data.description
                ? data.description
                : data.updated.split(" ").slice(0, data.updated.indexOf(","))
            }.xlsx`
          );
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "failed",
            title: error.response.message,
          });
        });
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "failed",
        title: error.response.message,
      });
    }
  };
  const handleFile = async (data) => {
    try {
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheet = workbook.SheetNames[0];
      const excelRows = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[firstSheet]
      );
      const finalArray = excelRows.map(function (obj) {
        return obj.SKU ? obj.SKU.toString() : "";
      });

      if (finalArray.includes("")) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: `SKU is empty please check your excel file template`,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setSearch({
              value: "",
            });
          }
        });
        return;
      }
      setExcelDatas(finalArray);
      setOpenModalUpload(true);

      //flush data
      setExcelFile(null);
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: `import failed, please check your excel file template ${error}`,
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          setSearch({
            value: "",
          });
        }
      });
    }
  };
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CloudDownloadIcon />}
          label="Download"
          onClick={() => {
            downloadExcel(params.row);
          }}
          disabled={params.row.status === "pending" ? true : false}
          showInMenu
        />,
      ],
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 300,
      sortable: false,
    },

    {
      field: "calculation_id",
      headerName: "Calculation ID",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      headerAlign: "center",
    },
    {
      align: "center",
      headerAlign: "center",
      field: "periodFrom",
      headerName: "periodFrom",
      width: 180,
    },
    {
      field: "periodTo",
      headerName: "periodTo",
      width: 180,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "updated",
      headerName: "Updated",
      align: "center",
      headerAlign: "center",
      width: 180,

      sortable: false,
    },
  ];
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  const closeModal = () => {
    setOpenModalUpload(false);
    setExcelDatas([]);
    setExcelFile(null);
    refresh();
  };

  return (
    <div className="bg-white">
      <div className="text-left pl-2 pb-2 font-bold text-3xl">
        {
          <UploadData
            show={openModalUpload}
            handleClose={closeModal}
            datas={excelDatas}
          />
        }
        <h2>Report Calculation Detail Data</h2>
        <Button
          disabled={loading}
          // onClick={openModal}
          style={{
            borderRadius: 5,
            backgroundColor: `${loading ? "#ccc" : "#428777"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          component="label"
          endIcon={<BackupIcon />}
        >
          Upload SKU
          <input
            sx={{
              display: "none",
            }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            hidden
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            required
            label="Upload Data"
            onChange={(e) => {
              let fileTypes = [
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "text/csv",
              ];
              let selectedFile = e.target.files[0];
              if (selectedFile) {
                if (selectedFile && fileTypes.includes(selectedFile.type)) {
                  setTypeError(null);
                  let reader = new FileReader();
                  reader.readAsArrayBuffer(selectedFile);
                  reader.onload = (e) => {
                    setExcelFile(e.target.result);
                  };
                  console.log(e.target.files[0].name);

                  if (typeof FileReader !== "undefined") {
                    const reader = new FileReader();
                    if (reader.readAsBinaryString) {
                      reader.onload = (e) => {
                        handleFile(reader.result);
                      };
                      reader.readAsBinaryString(e.target.files[0]);
                    }

                    //clear reader
                    reader.onloadend = (e) => {
                      reader.onload = null;
                    };
                    selectedFile = null;
                    e.target.value = null;
                  } else {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                      },
                    });

                    Toast.fire({
                      icon: "error",
                      title: "This browser does not support HTML5.",
                    }).then((result) => {
                      /* Read more about handling dismissals below */
                      if (result.dismiss === Swal.DismissReason.timer) {
                        setSearch({
                          value: "",
                        });
                      }
                    });
                    e.target.value = null;
                  }
                } else {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });

                  Toast.fire({
                    icon: "error",
                    title: "lease select only excel file types",
                  }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                      setSearch({
                        value: "",
                      });
                    }
                  });
                  e.target.value = null;
                }
              } else {
                console.log("Please select your file");
                e.target.value = null;
              }
            }}
          />
        </Button>
      </div>

      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Download List"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1"></TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="flex flex-wrap text-justify mr-2 ml-2 pb-2">
        {/* <div className="w-full lg:w-9/12 overflow-auto"> */}
        <div className="w-full overflow-auto">
          <Box marginRight={"2px"}>
            <Box height="80vh">
              <StripedDataGrid
                // loading={isLoading || !data}
                getRowId={(row) => row.id}
                columns={columns}
                rowCount={(limit && total) || 0}
                rowsPerPageOptions={[20, 50, 100]}
                rows={data || []}
                pagination
                loading={loading}
                paginationMode="server"
                sortingMode="server"
                page={page}
                pageSize={limit}
                onPageSizeChange={(newPageSize) => (
                  setlimit(newPageSize), setLoading(true)
                )}
                onPageChange={(newPage) => (setPage(newPage), setLoading(true))}
                onSortModelChange={(newSortModel) =>
                  setDataOrder(...newSortModel)
                }
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                components={{ Toolbar: DataGridCustomToolbar }}
                componentsProps={{
                  toolbar: { searchInput, setSearchInput, setSearch },
                }}
              />
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default Report;
