import {
  DELETE_DATA_FAILED,
  DELETE_DATA_SUCCESS,
  GET_MATERIALS_FAILED,
  GET_MATERIALS_SUCCESS,
} from "../actions/types";

const initialState = {
  openDialogBOM: false,
  message: "",
  materials: [],
};

const dataReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_DATA_SUCCESS:
      return {
        ...state,
        message: payload.message,
      };
    case DELETE_DATA_FAILED:
      return {
        ...state,
        message: payload.message,
      };
    case GET_MATERIALS_SUCCESS:
      return {
        ...state,
        message: payload.message,
        materials: payload.materials,
      };
    case GET_MATERIALS_FAILED:
      return {
        ...state,
        message: payload.message,
      };
    default:
      return state;
  }
};

export default dataReducer;
