import { Button } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Swal from "sweetalert2";
import { useState } from "react";
import CardCopiedBomData from "./CardCopiedBomData";

const CardItem = ({
  sku_name,
  plant,
  sku,
  status,
  base_qty,
  uom,
  component,
  ksbt,
  valid_from,
  valid_to,
  routing,
  SKU_props,
  dataSKUList,
}) => {
  const [open, setOpen] = useState(false);

  let datas = {
    sku: sku,
    sku_name: sku_name,
    base_qty: base_qty,
    uom: uom,
    plant: plant,
    valid_from: valid_from,
    valid_to: valid_to,
    component: component,
    ksbt: ksbt,
    routing: routing,
  };

  const [copiedData, setCopiedData] = useState({});
  const closeModal = () => {
    setOpen(false);
  };
  const copy = (data) => {
    setCopiedData(data);
    setTimeout(() => {
      console.log("copy data from component", data);
    }, 500);

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: `SKU ${sku} from plant ${plant} copied successfully`,
    });
  };
  const edit = () => {
    if (copiedData.sku === undefined) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please copy the SKU first",
      });
    }
    setCopiedData(datas);
    setOpen(true);
  };
  return (
    <div className="flex flex-col md:flex-row md:justify-between mt-2">
      {
        <CardCopiedBomData
          open={open}
          closeModal={closeModal}
          data={copiedData}
          dataSKUList={dataSKUList}
        />
      }
      <div className="flex">
        <div
          name=" grey window"
          className="h-[130px] w-[130px] bg-[#d9d9d9]"
        ></div>
        <div name="text" className="md :flex md:flex-col md:pt-0">
          <div className="ml-2 text-left text-[#e44] md:text-xl text-base">
            {sku_name}
          </div>
          <div className="md:text-l ml-2 text-left text-sm text-[#b4b4b4]">
            {`Plant: ${plant}`}
          </div>
          <div className="md:text-l ml-2 text-left text-sm text-[#b4b4b4]">
            {`SKU: ${sku}`}
          </div>
          <div className="md:text-l ml-2 text-left text-sm text-[#b4b4b4]">
            {`Status: ${status}`}
          </div>
          <div className="md:text-l ml-2 text-left text-sm text-[#b4b4b4]">
            {`Base Qty :${base_qty}, UOM: ${uom}`}
          </div>
        </div>
      </div>

      <div name="Buttons">
        <div className="mt-2 flex gap-4 md:mt-0 md:flex-col">
          <div className="rounded-md shadow">
            <Button
              variant="outlined"
              size="medium"
              color="error"
              startIcon={<ContentCopyIcon />}
              onClick={() => copy(datas)}
              className="inline-flex items-center justify-center rounded-md border border-transparent w-32 bg-indigo-600  text-base font-medium text-white hover:bg-indigo-700 "
            >
              Copy
            </Button>
          </div>
          <div className="rounded-md shadow">
            <Button
              variant="outlined"
              size="medium"
              startIcon={<ModeEditIcon />}
              color="warning"
              onClick={() => edit()}
              className="inline-flex items-center justify-center rounded-md border border-transparent w-32 bg-indigo-600  text-base font-medium text-white hover:bg-indigo-700"
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
