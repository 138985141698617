import { useState, useRef } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";
import "../../App.css";
import "../../index.css";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import "react-spinner-animated/dist/index.css";
import { getMaterials, create_bom } from "../../actions/data";
import MaterialReactTable from "material-react-table";
import InventoryIcon from "@mui/icons-material/Inventory";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import FactoryIcon from "@mui/icons-material/Factory";
import BadgeIcon from "@mui/icons-material/Badge";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import { createMaterial } from "../../actions/data";
import {
  Button,
  createTheme,
  Box,
  Tooltip,
  Autocomplete,
  ThemeProvider,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  InputAdornment,
  TextField,
} from "@mui/material";

import { useEffect, useMemo, useCallback } from "react";

const CardCopiedBomData = (props) => {
  const plant = useMemo(
    () => [
      "J103",
      "K103",
      "K104",
      "K122",
      "K124",
      "K130",
      "K131",
      "K132",
      "K135",
      "K138",
      "S104",
      "S110",
      "S112",
      "S118",
      "S115",
      "K116",
      "K118",
    ],
    []
  );
  const type = useMemo(
    () => [
      "pm",
      "rm",
      "sfg",
      "premix",
    ],
    []
  );
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    customClass: {
      container: 'my-swal'
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const [validationErrors, setValidationErrors] = useState({});

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(() => props.data.component);
  const dispatch = useDispatch();
  const globalTheme = useTheme();
  const [loading, setLoading] = useState(false);
  const [valuess, setValues] = useState({});
  const [dataTemp, setDataTemp] = useState(() => props.data.component);
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
  };
  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      // Access values from the cell object
      const { value, row } = cell;
      const { plant, material, uom, type, qty, material_name } = row.original;

      // Create an object containing all column values
      const rowData = {
        plant,
        material,
        uom,
        type,
        qty,
        material_name
      };
      // Here you can perform any operations with the value or column object

      // console.log("Column:", rowData);
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (e) => {
          const isValid = cell.column.id === "material" ? validateMaterialCode(e.target.value) : true;
          if (!isValid) {
            setValidationErrors((prev) => ({
              ...prev,
              [cell.id]: "Material Code must start with 1, 2, 41, 42, 43",
            }));
          } else {
            setValidationErrors((prev) => {
              const { [cell.id]: _, ...rest } = prev;
              return rest;
            });
          }
        }
      }
    }, [validationErrors],
  );
  const { materials } = useSelector((state) => state.Data);
  const { message } = useSelector((state) => state.Message);
  const [TableData, setTableData] = useState(() => props.data.component);
  const columns = useMemo(
    () => [
      {
        accessorKey: "plant",
        header: "Plant",
        size: 20,
        enableColumnActions: false,
        enableEditing: false, //disable editing on this column
        enableColumnOrdering: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          select: false, //change to select for a dropdown
          children: plant.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          )),
        }),

      },

      {
        accessorKey: "material",
        header: "Material",
        size: 20,
        enableColumnActions: false,
        enableColumnOrdering: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "material_name",
        header: "Material Name",
        size: 30,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "qty",
        header: "Quantity",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        size: 30,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "uom",
        header: "UOM",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        size: 20,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },

    ],
    [plant, getCommonEditTextFieldProps]
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            "&:last-child": {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        overrides: {
          MuiTableCell: {
            root: {
              color: "grey",
              padding: "0px",
            },
          },
        },
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(235,236,236)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },

        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "red", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  useEffect(
    (prev) => {
      setValues((values) => ({
        short_description: "",
        ...props.data,
      }));
      setTableData(props.data.component);
      setDataTemp(props.data.component);
    },
    [props.data, props.data.component]
  );
  const checkMaterialExists = (material) => {
    return TableData.some(
      (row) =>
        row.material === material.material

    );
  };
  const price = {
    januari: 0,
    februari: 0,
    maret: 0,
    april: 0,
    mei: 0,
    juni: 0,
    juli: 0,
    agustus: 0,
    september: 0,
    oktober: 0,
    november: 0,
    desember: 0,
  }
  const determineExpectedType = (materialValue) => {
    if (materialValue.charAt(0) === "1") {
      return "rm";
    } else if (materialValue.charAt(0) === "2") {
      return "pm";
    } else if (materialValue.substring(0, 2) === "41") {
      return "premix";
    } else if (
      materialValue.substring(0, 2) === "42" ||
      materialValue.substring(0, 2) === "43"
    ) {
      return "sfg";
    }
    return null; // Return null if no expected type found
  };
  const handleSaveRowEdits = async ({
    exitEditingMode,
    row,
    columns,
    values,
  }) => {

    if (checkMaterialExists(values)) {
      if (!Object.keys(validationErrors).length) {
        TableData[row.index] = {
          plant: values.plant,
          material: values.material,
          uom: values.uom,
          type: values.type,
          qty: parseFloat(values.qty),
          material_name: TableData[row.index].material_name,
        };

        //send/receive api updates here, then refetch or update local table data for re-render
        setTableData([...TableData]);
        setValues({ ...valuess, component: TableData });

        exitEditingMode(); //required to exit editing mode and close modal
      }
      return;
    }
    if (!Object.keys(validationErrors).length) {
      console.log(price)
      console.log(values);
      console.log(checkMaterialExists(values));
      if (TableData.some(
        (row) =>
          row.material_name === values.material_name

      )) {
        return Toast.fire({
          icon: "error",
          title: `Change Material Name ${values.material_name} Already Existed❌`,
        })
      };
      const data = {
        material: values.material,
        plant: values.plant,
        type: determineExpectedType(values.material),
        material_description: values.material_name,
        price: [
          {
            date: "januari",
            price: 0,
          },
          {
            date: "februari",
            price: 0,
          },
          {
            date: "maret",
            price: 0,
          },
          {
            date: "april",
            price: 0,
          },
          {
            date: "mei",
            price: 0,
          },
          {
            date: "juni",
            price: 0,
          },
          {
            date: "juli",
            price: 0,
          },
          {
            date: "agustus",
            price: 0,
          },
          {
            date: "september",
            price: 0,
          },
          {
            date: "oktober",
            price: 0,
          },
          {
            date: "november",
            price: 0,
          },
          {
            date: "desember",
            price: 0,
          },
        ]
      }
      dispatch(createMaterial(data))
        .then(() => {
          Toast.fire({
            icon: "success",
            title: `Success to Create Material✔️`,
          });
          TableData.push({
            plant: values.plant,
            material: values.material,
            uom: values.uom,
            type: determineExpectedType(values.material),
            qty: parseFloat(values.qty),
            material_name: values.material_name,

          })
          console.log(TableData);
          setTableData([...TableData]);
          setDataTemp([...TableData]);

          setValues({ ...valuess, component: TableData });

          exitEditingMode(); //required to exit editing mode and close modal

        })
        .catch((err) => {
          console.log(message);
          Toast.fire({
            icon: "error",
            title: `Failed to Update Create Material ❌`,
          });
        });

    }
  }
  const validateMaterialCode = (material) => {
    // Regular expression to match the specified criteria
    const regex = /^(1|2|41|42|43).*/;

    // Test if the material code matches the regular expression
    return regex.test(material);
  };
  const handleCreateMaterial = (value) => {
    TableData.push(value);
    setTableData([...TableData]);
    setValues({ ...valuess, component: TableData });
  };
  const closeModal = () => {
    setOpen(false);
  };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };
  const handleChange = (e) => {
    if (e.target.name === "base_qty") {
      if (e.target.value === "" || e.target.value === null) {
        const { name, value } = e.target;
        setValues({ ...valuess, [name]: parseFloat(0) });
      } else {
        const { name, value } = e.target;
        setValues({
          ...valuess,
          [name]: parseFloat(value.replaceAll(",", ".")),
        });
      }
    } else {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: value });
    }
  };
  const resetValues = () => {
    setTableData(dataTemp);

    props.closeModal();
  };

  //Create BOM
  const submitData = (e) => {
    e.preventDefault();
    console.log(valuess);
    dispatch(create_bom(valuess))
      .then(() => {
        Toast.fire({
          icon: "success",
          title: `New SKU ${valuess.sku} Success Created✅`,
        });
        props.dataSKUList();
        props.closeModal();
      })
      .catch((err) => {

        props.closeModal();
        Toast.fire({
          icon: "error",
          title: `Failed to Create New SKU: ${message ? message : "SKU Already Existed"
            } ❌`,
        });
      });
  };

  // const handleDeleteRow = useCallback(
  //   (row) => {
  //     TableData.splice(row.index, 1);
  //     setTableData([...TableData]);

  //     // setValues({ ...valuess, component: TableData });
  //   },
  //   [TableData]
  // );

  const validate = useCallback((instance) => {
    // validate as usual
    setTableData(() => [...instance]);
  }, []);
  // const handleDeleteRow = async (row) => {
  //   TableData.splice(row.index, 1);
  //   setTableData(() => [...TableData]);
  //   validate(TableData);

  //   // setValues({ ...valuess, component: TableData });
  // };

  const handleDeleteRow = useCallback(
    (row) => {
      TableData.splice(row.index, 1);
      setDataTemp(TableData);
      setTableData(() => [...TableData]);
    },
    [TableData]
  );
  useEffect(() => {
    setTableData(dataTemp);
  }, [TableData, dataTemp]);
  const getDataMaterials = (e) => {
    setLoading(true);
    // if (materials.length > 0 && materials[0].plant === props.data.plant) {
    //   setLoading(false);
    //   setOpen(true);
    //   return;
    // }
    dispatch(getMaterials(props.data.plant)).then(() => {
      setLoading(false);
      setOpen(true);
      console.log("getDataMaterials SUccess");
    });
  };
  return (
    <Dialog
      style={{}}
      open={props.open}
      onClose={resetValues}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          position: "sticky",
        }}
      >
        Change Quantity BOM
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={resetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {
        <CardSelectMaterial
          columns={columns}
          open={open}
          closeModal={closeModal}
          createMaterial={handleCreateMaterial}
        />
      }
      {/* <form onSubmit={submitData}> */}
      <DialogContent dividers>
        <DialogContentText>
          Edit New SKU from SKU {props.data.sku ? props.data.sku : ""} from
          Plant {props.data.plant ? props.data.plant : ""} in The fields below:
        </DialogContentText>

        <TextField
          margin="normal"
          variant="standard"
          label="SKU"
          name="sku"
          sx={{
            marginRight: 2,
          }}
          value={valuess.sku}
          onChange={handleChange}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BreakfastDiningIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="SKU Name"
          name="sku_name"
          sx={{
            marginRight: 2,
          }}
          value={valuess.sku_name}
          onChange={handleChange}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BadgeIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="Plant"
          name="plant"
          sx={{
            marginRight: 2,
          }}
          value={valuess.plant}
          onChange={handleChange}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FactoryIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="Base Quantity"
          name="base_qty"
          sx={{
            marginRight: 2,
          }}
          value={valuess.base_qty}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EqualizerIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="UOM"
          name="uom"
          sx={{
            marginRight: 2,
          }}
          value={valuess.uom}
          onChange={handleChange}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InventoryIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="Short Description"
          name="short_description"
          sx={{
            marginRight: 2,
          }}
          value={valuess.short_description}
          onChange={handleChange}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {props.type ? props.type : ""}
              </InputAdornment>
            ),
          }}
          required
        />
        <div className="mt-2">
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={TableData ? TableData : []}
              enableHiding={false}
              enableColumnFilters={false}
              enableDensityToggle={false}
              enableStickyHeader
              enableClickToCopy
              enableEditing
              enableColumnOrdering
              onEditingRowSave={handleSaveRowEdits}
              onEditingRowCancel={handleCancelRowEdits}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Tooltip arrow placement="left" title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteRow(row)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              renderTopToolbarCustomActions={() => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 1,
                  }}
                >
                  <Button
                    color="success"
                    size="medium"
                    sx={{
                      borderRadius: 2,
                      fontSize: "0.875rem",
                      whiteSpace: { xs: "nowrap" }, // Allow text truncation only on small screens
                      overflow: { xs: "hidden" }, // Hide overflowed text on small screens
                      textOverflow: { xs: "ellipsis" }, // Show ellipsis for truncated text on small screens
                    }}
                    onClick={() => getDataMaterials()}
                    variant="outlined"
                  >
                    {loading ? `Loading...` : `Add Material`}
                  </Button>
                  <Button
                    color="success"
                    size="medium"
                    disabled={true}
                    sx={{
                      borderRadius: 2,
                      fontSize: "0.875rem",
                      whiteSpace: { xs: "nowrap" }, // Allow text truncation only on small screens
                      overflow: { xs: "hidden" }, // Hide overflowed text on small screens
                      textOverflow: { xs: "ellipsis" }, // Show ellipsis for truncated text on small screens
                    }}
                    onClick={() => console.log("Add Material")}
                    variant="outlined"
                  >
                    {/* {loading ? `Loading...` : `Create Material`} */}
                    {`Create Material`}
                  </Button>
                </Box>
              )}
              muiTableContainerProps={{
                sx: { maxHeight: "500px", margin: "2px" },
              }}
              getRowId={(row) => row.id}
              muiTableHeadCellProps={{
                align: "center",
                sx: {
                  borderRight: " solid #e0e0e0",
                  alignItems: "center",
                  // "& .Mui-TableHeadCell-Content-Labels": {
                  //   padding: "0px",
                  // },
                  "& .MuiBox-root": {
                    paddingLeft: "0px",
                  },
                  backgroundColor: "white",

                  borderTop: " solid #e0e0e0",
                },
              }}
              muiTableBodyCellProps={{
                align: "center",
                sx: {
                  borderRight: " solid #e0e0e0",
                  borderTop: " solid #e0e0e0",
                },
              }}
              muiSelectCheckboxProps={{
                color: "secondary",
              }}
              state={{
                density: "compact",
              }}
              muiTableBodyProps={{
                sx: {
                  //stripe the rows, make odd rows a darker color
                  "& td:nth-of-type(odd)": {
                    backgroundColor: "#f5f5f5",
                  },
                },
              }}
            />
          </ThemeProvider>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            valuess.sku_name === "" ||
              valuess.plant === "" ||
              valuess.base_qty === "" ||
              valuess.uom === "" ||
              valuess.short_description === "" ||
              valuess.sku === ""
              ? true
              : false
          }
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "green",
            },
          }}
          onClick={submitData}
          endIcon={<PublishedWithChangesIcon />}
        >
          Submit
        </Button>
      </DialogActions>
      {/* </form> */}
    </Dialog>
  );
};
export const CardSelectMaterial = (props) => {
  const [material, setMaterial] = useState(() =>
    props.columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );
  const [show, setShow] = useState(false);

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: "darkslategray",
    backgroundColor: "aliceblue",
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });
  const { materials } = useSelector((state) => state.Data);

  const resetValues = () => {
    props.closeModal();
  };
  let data = materials.filter(
    (v, i, a) => a.findIndex((v2) => v2.material_name === v.material_name) === i
  );
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && material !== "" && material !== null) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const options = data.map((option) => {
    const firstLetter = option.material_name[0].toUpperCase();

    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const onSubmit = (e) => {
    e.preventDefault();
    delete material["firstLetter"];
    props.createMaterial({ ...material, qty: 0, uom: "" });
    props.closeModal();
  };
  const handleSetSKU = (e) => {
    if (e !== "" && e !== null) {
      setMaterial(e);
    } else {
      setMaterial(e);
      setShow(false);
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={resetValues}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Select Material for BOM
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={resetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogContent dividers>
          <DialogContentText>
            Select Material for new SKU from Fields Below:
          </DialogContentText>
          <Autocomplete
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            style={{ width: "100%", color: "#ffffff", marginTop: "5px" }}
            onChange={(event, newValue) => {
              handleSetSKU(newValue);
            }}
            id="grouped-demo"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option, index) =>
              option.material_name +
              `, code:(${option.material})` +
              `, plant:(${option.plant})`
            }
            sx={{ width: 300 }}
            renderInput={(params) => {
              params.inputProps.onKeyDown = handleKeyDown;
              return <TextField {...params} label="Search by SKU" required />;
            }}
            renderGroup={(params) => (
              <ul key={params.key}>
                <li>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              </ul>
            )}
          />
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          type="submit"
          onClick={onSubmit}
          variant="contained"
          sx={{
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "green",
            },
          }}
          endIcon={<PublishedWithChangesIcon />}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CardCopiedBomData;
