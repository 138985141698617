import { useState, useRef } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";

import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import "react-spinner-animated/dist/index.css";
import { getMaterials, update_allowance } from "../../actions/data";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import {
  Button,
  createTheme,
  Box,
  Tooltip,
  Autocomplete,
  ThemeProvider,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import { useEffect, useMemo, useCallback } from "react";
import { set } from "react-hook-form";

const ChangeAllowance = (props) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const dispatch = useDispatch();
  const globalTheme = useTheme();
  const [loading, setLoading] = useState(false);
  const [valuess, setValues] = useState(props.data);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
  };

  const { message } = useSelector((state) => state.Message);

  useEffect(
    (prev) => {
      setValues(() => props.data);
    },
    [props.data]
  );

  const handleChange = (e) => {
    if (
      e.target.value === "" ||
      e.target.value === null ||
      e.target.value < 0
    ) {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: parseFloat(0) });
    } else {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: parseFloat(value) });
    }
  };
  const resetValues = () => {
    setValues({
      type: "",
      current: 0,
      januari: 0,
      februari: 0,
      maret: 0,
      april: 0,
      mei: 0,
      juni: 0,
      juli: 0,
      agustus: 0,

      september: 0,
      oktober: 0,
      november: 0,
      desember: 0,
    });
    props.closeModal();
  };

  //Create BOM
  const submitData = (e) => {
    e.preventDefault();

    console.log({
      type: valuess.type,
      allowance: {
        januari: valuess.januari,
        februari: valuess.februari,
        maret: valuess.maret,
        april: valuess.april,
        mei: valuess.mei,
        juni: valuess.juni,
        juli: valuess.juli,
        agustus: valuess.agustus,
        september: valuess.september,
        oktober: valuess.oktober,
        november: valuess.november,
        desember: valuess.desember,
        current: valuess.current,
      },
    });
    const data = {
      type: valuess.type,
      allowance: {
        januari: valuess.januari,
        februari: valuess.februari,
        current: valuess.current,
        maret: valuess.maret,
        april: valuess.april,
        mei: valuess.mei,
        juni: valuess.juni,
        juli: valuess.juli,
        agustus: valuess.agustus,
        september: valuess.september,
        oktober: valuess.oktober,
        november: valuess.november,
        desember: valuess.desember,
      },
    };
    dispatch(update_allowance(data))
      .then(() => {
        Toast.fire({
          icon: "success",
          title: `Success Updated Allowance Value✔️`,
        });

        resetValues();
        props.refreshData();
      })
      .catch((err) => {
        resetValues();
        Toast.fire({
          icon: "error",
          title: `Failed to Update Allowance Value ❌`,
        });
      });
  };

  return (
    <Dialog
      style={{}}
      open={props.open}
      onClose={resetValues}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          position: "sticky",
        }}
      >
        Change Allowance Value
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={resetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {/* <form onSubmit={submitData}> */}
      <DialogContent dividers>
        <DialogContentText>
          Edit Allowance value for {valuess.type}
        </DialogContentText>
        <TextField
          margin="normal"
          variant="standard"
          label="januari"
          name="januari"
          sx={{
            marginRight: 2,
          }}
          value={valuess.januari}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="februari"
          name="februari"
          sx={{
            marginRight: 2,
          }}
          value={valuess.februari}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="maret"
          name="maret"
          sx={{
            marginRight: 2,
          }}
          value={valuess.maret}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="april"
          name="april"
          sx={{
            marginRight: 2,
          }}
          value={valuess.april}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="mei"
          name="mei"
          sx={{
            marginRight: 2,
          }}
          value={valuess.mei}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="juni"
          name="juni"
          sx={{
            marginRight: 2,
          }}
          value={valuess.juni}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="juli"
          name="juli"
          sx={{
            marginRight: 2,
          }}
          value={valuess.juli}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="agustus"
          name="agustus"
          sx={{
            marginRight: 2,
          }}
          value={valuess.agustus}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="september"
          name="september"
          sx={{
            marginRight: 2,
          }}
          value={valuess.september}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="oktober"
          name="oktober"
          sx={{
            marginRight: 2,
          }}
          value={valuess.oktober}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="november"
          name="november"
          sx={{
            marginRight: 2,
          }}
          value={valuess.november}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="desember"
          name="desember"
          sx={{
            marginRight: 2,
          }}
          value={valuess.desember}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="current"
          name="current"
          sx={{
            marginRight: 2,
          }}
          value={valuess.current}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "green",
            },
          }}
          onClick={submitData}
          endIcon={<PublishedWithChangesIcon />}
        >
          Submit
        </Button>
      </DialogActions>
      {/* </form> */}
    </Dialog>
  );
};

export default ChangeAllowance;
