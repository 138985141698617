import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";

import Swal from "sweetalert2";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import api from "../../configs/api";
import { Delete, Edit, Upload } from "@mui/icons-material";
import BackupIcon from "@mui/icons-material/Backup";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "@mui/material";
import fileSaver from "file-saver";
import {
  UploadFile,
  DeleteMaterial,
  UpdateMaterial,
} from "../../components/Modals";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import CreateMaterial from "../../components/Modals/CreateMaterial";
const Material = () => {
  const [value, setValue] = React.useState("1");
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  const dispatch = useDispatch();
  // values to be sent to the backend

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: "material",
      desc: false,
    },
  ]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [globalFilter, setGlobalFilter] = useState("");

  let page = pagination.pageIndex;
  let limit = pagination.pageSize;

  useEffect(() => {
    const dataHeader = [
      "id",
      "material",
      "material_name",
      "plant",
      "type",
      "current_price",
    ];
    let search;

    if (!globalFilter) {
      setIsLoading(true);
      search = {
        value: "",
      };
    } else {
      setIsLoading(true);
      search = {
        value: globalFilter,
      };
    }
    let sort;
    if (sorting.length < 1) {
      setSorting([
        {
          id: "material",
          desc: false,
        },
      ]);
      sort = sorting.map((el) => {
        let datafix = {};
        if (el.desc) {
          datafix["column"] = String(dataHeader.indexOf(el.id));
          datafix["dir"] = "desc";
        } else {
          datafix["column"] = String(dataHeader.indexOf(el.id));
          datafix["dir"] = "asc";
        }
        return datafix;
      });
      return;
    }
    sort = sorting.map((el) => {
      let datafix = {};
      if (el.desc) {
        datafix["column"] = String(dataHeader.indexOf(el.id));
        datafix["dir"] = "desc";
      } else {
        datafix["column"] = String(dataHeader.indexOf(el.id));
        datafix["dir"] = "asc";
      }
      return datafix;
    });
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/master/list_material",
          { page, limit, order: sort, search },
          { signal: abortController.signal }
        );

        if (data) {
          setData(data.data.data);
          setRowCount(data.data.recordsFiltered);
          setTimeout(() => {
            setIsLoading(false);
            setIsError(false);
          }, 500);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);
          setIsError(false);
        }
      }
    };

    fetchData();
    return () => abortController.abort();
  }, [pagination, globalFilter, sorting, limit, page, dispatch]);
  const globalTheme = useTheme();

  const refresh = async (dataSearch) => {
    const dataHeader = [
      "id",
      "material",
      "material_name",
      "plant",
      "type",
      "current_price",
    ];
    let search = {
      value: "",
    };

    if (!globalFilter) {
      setIsLoading(true);
      search = {
        value: "",
      };
    } else {
      setIsLoading(true);
      search = {
        value: "",
      };
    }
    let sort;
    if (sorting.length < 1) {
      setSorting([
        {
          id: "material",
          desc: false,
        },
      ]);
      sort = sorting.map((el) => {
        let datafix = {};
        if (el.desc) {
          datafix["column"] = String(dataHeader.indexOf(el.id));
          datafix["dir"] = "desc";
        } else {
          datafix["column"] = String(dataHeader.indexOf(el.id));
          datafix["dir"] = "asc";
        }
        return datafix;
      });
      return;
    }
    sort = sorting.map((el) => {
      let datafix = {};
      if (el.desc) {
        datafix["column"] = String(dataHeader.indexOf(el.id));
        datafix["dir"] = "desc";
      } else {
        datafix["column"] = String(dataHeader.indexOf(el.id));
        datafix["dir"] = "asc";
      }
      return datafix;
    });
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/master/list_material",
          {
            page,
            limit,
            order: sort,
            search: {
              value: dataSearch,
            },
          },
          { signal: abortController.signal }
        );

        if (data) {
          setData(data.data.data);
          setRowCount(data.data.recordsFiltered);
          setTimeout(() => {
            setIsLoading(false);
            setIsError(false);
          }, 500);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);
          setIsError(false);
        }
      }
    };

    fetchData();
    return () => abortController.abort();
  };
  const downloadExcel = () => {
    try {
      api
        .get("/api/master/template/material", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          fileSaver.saveAs(blob, "template-material.xlsx");
        });
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "failed",
        title: error.response.message,
      });
    }
  };
  const exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      api
        .post(
          "/api/master/list_material",
          {
            page,
            limit: 100000,
            order: [
              {
                column: "0",
                dir: "asc",
              },
            ],
            search: {
              value: "",
            },
          },
          {}
        )
        .then((response) => {
          console.log("response", response.data.data);
          const transformed = response.data.data.data.map((el) => {
            let temItem = el;
            el.price.forEach((item) => {
              temItem = { ...temItem, [item.date]: item.price };
            });
            return temItem;
          });
          exportToExcel(transformed, "Material-Data");
          setIsLoading(false);
        });
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "failed",
        title: error.response.message,
      });
    }
  };
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(235,236,236)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },

        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "red", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  //columns Header
  const columns = useMemo(
    () => [
      {
        id: "data",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        header: "Details",
        columns: [
          {
            accessorKey: "material",
            size: 50,
            header: "Material",
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "material_name",
            header: "Material Name",
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "plant",
            header: "Plant",
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "type",
            header: "Type",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "current_price",
            header: "Current Price",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
        ],
      },

      {
        id: "grouped",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        header: "Price 💲",
        columns: [
          {
            accessorKey: "januari",
            header: "Januari",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "februari",
            header: "Februari",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "maret",
            header: "Maret",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "april",
            header: "April",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "mei",
            header: "Mei",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "juni",
            header: "Juni",

            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "juli",
            header: "Juli",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "agustus",
            header: "Agustus",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "september",
            header: "September",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "oktober",
            header: "Oktober",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "november",
            header: "November",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
          {
            accessorKey: "desember",
            header: "Desember",
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => (
              <>
                {cell.getValue()?.toLocaleString?.("en-US", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            ),
          },
        ],
      },
      //column definitions...
    ],
    []
  );

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    // tableData[row.index] = values;
    // //send/receive api updates here
    // setTableData([...tableData]);

    console.log("row", values);

    exitEditingMode(); //required to exit editing mode
  };

  //transform data
  const transformed = data.map((el) => {
    let temItem = el;
    el.price.forEach((item) => {
      temItem = { ...temItem, [item.date]: item.price };
    });
    return temItem;
  });

  const [opemModal, setOpenModal] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const openModal = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
    setOpenDelete(false);
    setOpenModalAdd(false);
    setOpenUpdate(false);
  };
  const [material, setMaterial] = useState({
    material: "",
    plant: "",
  });
  const openModalDelete = (row) => {
    setMaterial({
      material: row.getValue("material"),
      plant: row.getValue("plant"),
    });
    setOpenDelete(true);
  };
  const openModalUpdate = (row) => {
    setMaterial({
      material: row.getValue("material"),
      plant: row.getValue("plant"),
    });
    setOpenUpdate(true);
  };
  //render table
  return (
    <div className="bg-white">
      {
        <DeleteMaterial
          open={openDelete}
          closeModal={closeModal}
          material={material}
          refresh={refresh}
        />
      }
      {
        <UpdateMaterial
          open={openUpdate}
          closeModal={closeModal}
          material={material}
          refresh={refresh}
        />
      }
      {
        <UploadFile
          show={opemModal}
          handleClose={closeModal}
          Type="Material"
          refresh={refresh}
        />
      }
      {
        <CreateMaterial
          open={openModalAdd}
          closeModal={closeModal}
          refreshData={refresh}
        />
      }
      <div className="text-left pl-2 pb-2 font-bold text-3xl">
        <h2>Master Data Material</h2>
        <Button
          disabled={isLoading}
          onClick={downloadExcel}
          style={{
            borderRadius: 5,
            backgroundColor: `${isLoading ? "#ccc" : "#F7D716"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<FileDownloadIcon />}
        >
          Template
        </Button>
        <Button
          onClick={openModal}
          style={{
            borderRadius: 5,
            backgroundColor: `${isLoading ? "#ccc" : "#428777"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<BackupIcon />}
        >
          Upload Data
        </Button>
        <Button
          disabled={isLoading}
          onClick={fetchData}
          style={{
            borderRadius: 5,
            backgroundColor: `${isLoading ? "#ccc" : "#2D3732"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<CloudDownloadIcon />}
        >
          Export Data
        </Button>
      </div>
      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Material Data All"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
                {/* <Tab
                  label="Material Data"
                  value="2"
                  style={{ color: "#FF0000" }}
                /> */}
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1">
              <div className="m-2 rounded-t-md">
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    renderTopToolbarCustomActions={() => (
                      <Button
                        color="success"
                        size="medium"
                        sx={{
                          borderRadius: 2,
                          fontSize: "0.875rem",
                        }}
                        onClick={() => {
                          console.log("Create new account");
                          setOpenModalAdd(true);
                        }}
                        variant="outlined"
                      >
                        Add Material
                      </Button>
                    )}
                    columns={columns}
                    data={transformed}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableStickyHeader
                    enableGlobalFilterModes
                    initialState={{
                      showGlobalFilter: true,
                    }}
                    positionGlobalFilter="right"
                    muiSearchTextFieldProps={{
                      placeholder: `Search data`,
                      sx: { minWidth: "100px", minHeight: "10px" },
                    }}
                    editingMode="modal" //default
                    enableColumnOrdering
                    enableEditing
                    // renderTopToolbarCustomActions={() => (
                    //   <Button
                    //     style={{
                    //       borderRadius: 5,
                    //       backgroundColor: "#F7D716",
                    //       color: "#fff",
                    //       fontSize: "15px",
                    //     }}
                    //     onClick={() => console.log("Create new account")}
                    //     variant="contained"
                    //   >
                    //     Create Material
                    //   </Button>
                    // )}
                    onEditingRowSave={handleSaveRow}
                    onEditingRowCancel={(e) => console.log(e)}
                    renderRowActions={({ exitEditingMode, row, table }) => (
                      <Box sx={{ display: "flex", gap: "1rem" }}>
                        {/* <Tooltip arrow placement="left" title="Edit"> */}
                        <IconButton onClick={() => openModalUpdate(row)}>
                          <Edit />
                        </IconButton>
                        {/* </Tooltip>
                        <Tooltip arrow placement="right" title="Delete"> */}
                        <IconButton
                          color="error"
                          onClick={() => openModalDelete(row)}
                        >
                          <Delete />
                        </IconButton>

                        {/* </Tooltip> */}
                      </Box>
                    )}
                    muiTableContainerProps={{
                      sx: { maxHeight: "500px", margin: "1px" },
                    }}
                    getRowId={(row) => row.id}
                    manualPagination
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        borderRight: "2px solid #e0e0e0",
                        backgroundColor: "white",
                        borderTop: "2px solid #e0e0e0",
                        margin: "2px",
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                      sx: {
                        borderRight: "2px solid #e0e0e0",
                        borderTop: "2px solid #e0e0e0",
                        margin: "2px",
                      },
                    }}
                    manualSorting
                    muiToolbarAlertBannerProps={
                      isError
                        ? {
                            color: "error",
                            children: "Error loading data, No data found",
                          }
                        : undefined
                    }
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    state={{
                      globalFilter,
                      isLoading,
                      density: "compact",
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                    }}
                    muiTableBodyProps={{
                      sx: {
                        //stripe the rows, make odd rows a darker color
                        "& td:nth-of-type(odd)": {
                          backgroundColor: "#f5f5f5",
                        },
                        margin: "2px",
                        padding: "2px",
                      },
                    }}
                  />
                </ThemeProvider>
              </div>
            </TabPanel>
            <TabPanel style={{ padding: 2 }} value="2"></TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
export default Material;
