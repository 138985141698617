import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useState, useEffect } from "react";

const ChangeBomQty = (props) => {
  const [values, setValues] = useState({
    quantity: props.quantity,
  });
  useEffect(() => {
    setValues({ quantity: props.quantity });
  }, [props.quantity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const resetValues = () => {
    setValues({ quantity: 0 });
    props.closeModal();
  };
  return (
    <Dialog
      open={props.open}
      onClose={resetValues}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Change Quantity BOM
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={resetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={(e) =>
          props.confirmUpdate(props.bom_id, parseFloat(values.quantity))
        }
      >
        <DialogContent dividers>
          <DialogContentText>
            Edit BOM Quantity from Material {props.SKU ? props.SKU : ""} in The
            fields below:
          </DialogContentText>
          <TextField
            margin="normal"
            variant="standard"
            label="Quantity"
            name="quantity"
            value={values.quantity}
            onChange={handleChange}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {props.type ? props.type : ""}
                </InputAdornment>
              ),
            }}
            required
          />
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              endIcon={<PublishedWithChangesIcon />}
            >
              Update
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default ChangeBomQty;
