import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import api from "../../configs/api";
import { makeStyles } from "@mui/styles";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Delete, Edit, Upload } from "@mui/icons-material";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import TabPanel from "@mui/lab/TabPanel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import BackupIcon from "@mui/icons-material/Backup";
import { Button } from "@mui/material";
import { UploadFile } from "../../components/Modals";
import UpdateRouting from "../../components/Modals/UpdateRouting";
const Routing = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  const dispatch = useDispatch();
  // values to be sent to the backend

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: "id",
      desc: false,
    },
  ]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [datas, setDatas] = useState({});
  let page = pagination.pageIndex;
  let limit = pagination.pageSize;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const dataHeader = [
      "id",
      "cost_center",
      "short_description",
      "plant",
      "material",
      "material_name",
    ];
    let search;
    if (!globalFilter) {
      setIsLoading(true);
      search = {
        value: "",
      };
    } else {
      setIsLoading(true);
      search = {
        value: globalFilter,
      };
    }
    let sort;
    if (sorting.length < 1) {
      setSorting([
        {
          id: "id",
          desc: false,
        },
      ]);
      sort = sorting.map((el) => {
        let datafix = {};
        if (el.desc) {
          datafix["column"] = String(dataHeader.indexOf(el.id));
          datafix["dir"] = "desc";
        } else {
          datafix["column"] = String(dataHeader.indexOf(el.id));
          datafix["dir"] = "asc";
        }
        return datafix;
      });
      return;
    }
    sort = sorting.map((el) => {
      let datafix = {};
      if (el.desc) {
        datafix["column"] = String(dataHeader.indexOf(el.id));
        datafix["dir"] = "desc";
      } else {
        datafix["column"] = String(dataHeader.indexOf(el.id));
        datafix["dir"] = "asc";
      }
      return datafix;
    });
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/master/list_routing",
          { page, limit, order: sort, search },
          { signal: abortController.signal }
        );

        if (data) {
          setData(data.data.data);
          setRowCount(data.data.recordsFiltered);
          setTimeout(() => {
            setIsLoading(false);
            setIsError(false);
          }, 500);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);
          setIsError(false);

          console.log("err");
        }
      }
    };

    fetchData();
    return () => abortController.abort();
  }, [pagination, globalFilter, sorting, limit, page, dispatch]);

  const refresh = async () => {
    const dataHeader = [
      "id",
      "cost_center",
      "short_description",
      "plant",
      "material",
      "material_name",
    ];
    let search;
    if (!globalFilter) {
      setIsLoading(true);
      search = {
        value: datas.cost_center ? datas.cost_center : "",
      };
    } else {
      setIsLoading(true);
      search = {
        value: globalFilter,
      };
    }
    let sort;
    if (sorting.length < 1) {
      setSorting([
        {
          id: "id",
          desc: false,
        },
      ]);
      sort = sorting.map((el) => {
        let datafix = {};
        if (el.desc) {
          datafix["column"] = String(dataHeader.indexOf(el.id));
          datafix["dir"] = "desc";
        } else {
          datafix["column"] = String(dataHeader.indexOf(el.id));
          datafix["dir"] = "asc";
        }
        return datafix;
      });
      return;
    }
    sort = sorting.map((el) => {
      let datafix = {};
      if (el.desc) {
        datafix["column"] = String(dataHeader.indexOf(el.id));
        datafix["dir"] = "desc";
      } else {
        datafix["column"] = String(dataHeader.indexOf(el.id));
        datafix["dir"] = "asc";
      }
      return datafix;
    });
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/master/list_routing",
          { page, limit, order: sort, search },
          { signal: abortController.signal }
        );

        if (data) {
          setData(data.data.data);
          setRowCount(data.data.recordsFiltered);
          setTimeout(() => {
            setIsLoading(false);
            setIsError(false);
          }, 500);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);
          setIsError(false);

          console.log("err");
        }
      }
    };

    fetchData();
    return () => abortController.abort();
  };
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            "&:last-child": {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        overrides: {
          MuiTableCell: {
            root: {
              color: "grey",
              padding: "0px",
            },
          },
        },
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(235,236,236)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },

        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "red", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  //columns Header
  const columns = useMemo(
    () => [
      {
        id: "data",
        header: "Details",
        columns: [
          {
            accessorKey: "cost_center",
            size: 50,
            header: "Cost Center",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "short_description",
            size: 30,
            header: "Short Description",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "plant",
            size: 30,
            header: "Plant",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "material",
            size: 50,
            header: "Material",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "material_name",
            size: 50,
            header: "Material Name",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "base_quantity",
            size: 50,
            header: "Base Quantity",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "kwh",
            size: 50,
            header: "KWH",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "no_mc_mmbtu",
            size: 50,
            header: "No MC MMBTU",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "noempl",
            size: 50,
            header: "No Employee",
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
        ],
      },
      {
        id: "data",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        header: "Depreciation 📈",
        columns: [
          {
            accessorKey: "ZAP_DP_acttyp",
            size: 50,
            header: "Acctyp",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_DP_stdval",
            size: 50,
            header: "Stdval",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_DP_unit",
            size: 50,
            header: "Unit",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
        ],
      },
      {
        id: "data",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        header: "Electricity ⚡",
        columns: [
          {
            accessorKey: "ZAP_EL_acttyp",
            size: 50,
            header: "Acctyp",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_EL_stdval",
            size: 50,
            header: "Stdval",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_EL_unit",
            size: 50,
            header: "Unit",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
        ],
      },
      {
        id: "data",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        header: "Labour 👷",
        columns: [
          {
            accessorKey: "ZAP_LH_acttyp",
            size: 50,
            header: "Acctyp",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_LH_stdval",
            size: 50,
            header: "Stdval",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
          },
          {
            accessorKey: "ZAP_LH_unit",
            size: 50,
            header: "Unit",
            enableColumnActions: false,
            enableSorting: false,
            enableColumnOrdering: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
        ],
      },
      {
        id: "datas",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        header: "GAS⛽",
        columns: [
          {
            accessorKey: "ZAP_GS_acttyp",
            size: 50,
            header: "Acctyp",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_GS_stdval",
            size: 50,
            header: "Stdval",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_GS_unit",
            size: 50,
            header: "Unit",
            enableColumnActions: false,
            enableSorting: false,
            enableColumnOrdering: false,

            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
        ],
      },
      {
        id: "datas",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        header: "OMC 🏭",
        columns: [
          {
            accessorKey: "ZAP_OT_acttyp",
            size: 50,
            header: "Acctyp",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_OT_stdval",
            size: 50,
            header: "Stdval",
            enableColumnActions: false,
            enableColumnOrdering: false,
            enableSorting: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
          {
            accessorKey: "ZAP_OT_unit",
            size: 50,
            header: "Unit",
            enableColumnActions: false,
            enableSorting: false,
            enableColumnOrdering: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
        ],
      },
      //column definitions...
    ],
    []
  );
  const exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const nullToStr = (v) => (v === null ? "" : v);
  //transform data
  const transformed = (data) =>
    data.map((el) =>
      Object.fromEntries(
        Object.entries(el).flatMap(([key, val]) =>
          val && typeof val === "object"
            ? Object.entries(val).map(([k, v]) => [key + "_" + k, nullToStr(v)])
            : [[key, nullToStr(val)]]
        )
      )
    );
  const fetchData = async () => {
    setIsLoading(true);
    try {
      api
        .post(
          "/api/master/list_routing",
          {
            page,
            limit: 7000,
            order: [
              {
                column: "0",
                dir: "asc",
              },
            ],
            search: {
              value: "",
            },
          },
          {}
        )
        .then((response) => {
          console.log("response", response.data.data);
          // const transformed = response.data.data.data.map((el) => {
          //   let temItem = el;
          //   el.price.forEach((item) => {
          //     temItem = { ...temItem, [item.date]: item.price };
          //   });
          //   delete temItem.price;
          //   return temItem;
          // });

          exportToExcel(transformed(response.data.data.data), "Routing-Data");
          setIsLoading(false);
        });
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "failed",
        title: error.response.message,
      });
    }
  };

  const openModal = () => {
    setOpenModalUpload(true);
  };
  const closeModal = () => {
    setOpenModalUpload(false);
    setOpen(false);
  };
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    // tableData[row.index] = values;
    // //send/receive api updates here
    // setTableData([...tableData]);

    console.log("row", values);

    exitEditingMode(); //required to exit editing mode
  };

  //render table
  return (
    <div className="bg-white">
      {
        <>
          <UpdateRouting
            data={datas}
            open={open}
            refreshData={refresh}
            closeModal={closeModal}
          />
          <UploadFile
            show={openModalUpload}
            handleClose={closeModal}
            Type="Routing"
            refresh={refresh}
          />
        </>
      }
      <div className="text-left pl-2 pb-2 font-bold text-3xl">
        <h2>Master Data Routing</h2>
        <Button
          disabled={isLoading}
          onClick={openModal}
          style={{
            borderRadius: 5,
            backgroundColor: `${isLoading ? "#ccc" : "#428777"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<BackupIcon />}
        >
          Upload Data
        </Button>
        <Button
          disabled={isLoading}
          onClick={fetchData}
          s
          style={{
            borderRadius: 5,
            backgroundColor: `${isLoading ? "#ccc" : "#2D3732"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<CloudDownloadIcon />}
        >
          Export Data
        </Button>
      </div>

      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={"1"}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Master Data Routing"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1"></TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="m-2 rounded-t-md">
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={transformed(data)}
            // enableHiding={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableStickyHeader
            enableClickToCopy
            enableColumnOrdering
            enableGrouping
            enableGlobalFilterModes
            muiTableContainerProps={{
              sx: { maxHeight: "500px", margin: "1px" },
            }}
            getRowId={(row) => row.id}
            manualFiltering
            manualPagination
            muiTableHeadCellProps={{
              sx: {
                borderRight: " solid #e0e0e0",
                alignItems: "center",
                // "& .Mui-TableHeadCell-Content-Labels": {
                //   padding: "0px",
                // },
                "& .MuiBox-root": {
                  paddingLeft: "0px",
                },
                backgroundColor: "white",

                borderTop: " solid #e0e0e0",
              },
            }}
            initialState={{
              showGlobalFilter: true,
            }}
            enableEditing
            muiTableBodyCellProps={{
              sx: {
                borderRight: " solid #e0e0e0",
                borderTop: " solid #e0e0e0",
              },
            }}
            onEditingRowSave={handleSaveRow}
            renderRowActions={({ exitEditingMode, row, table }) => (
              <Box
                sx={{ alignItems: "center", gap: "1rem", alignSelf: "center" }}
              >
                {/* <Tooltip arrow placement="left" title="Edit"> */}
                <IconButton
                  onClick={() => {
                    setDatas(row.original);
                    setOpen(true);
                  }}
                >
                  <Edit />
                </IconButton>
              </Box>
            )}
            manualSorting
            muiToolbarAlertBannerProps={
              isError
                ? {
                    color: "error",
                    children: "Error loading data, No data found",
                  }
                : undefined
            }
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={rowCount}
            muiSelectCheckboxProps={{
              color: "secondary",
            }}
            state={{
              globalFilter,
              isLoading,
              density: "compact",
              pagination,
              showAlertBanner: isError,
              showProgressBars: isRefetching,
              sorting,
            }}
            muiTableBodyProps={{
              sx: {
                //stripe the rows, make odd rows a darker color
                "& td:nth-of-type(odd)": {
                  backgroundColor: "#f5f5f5",
                },
              },
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};
export default Routing;
