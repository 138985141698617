import React, { useReff } from "react";
import api from "../../configs/api";

import Swal from "sweetalert2";
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Input,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import BackupIcon from "@mui/icons-material/Backup";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "react-router-dom";

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "visible",
  },
  paper: { minWidth: "350px", maxWidth: "600px", marginLeft: "35px" },
  dialogContentRoot: {
    overflowY: "visible",
  },
  topScrollPaper: {
    marginTop: "-60px",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "50px",
  },
});
const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    maxWidth: "600px",
    color: "red",
  },
  error: {}, //<--this is required to make it work
}));

const UploadFile = ({ show, handleClose, Type, refresh }) => {
  console.log(Type);
  const [title, setTitle] = React.useState("Upload File");
  const [file, setFile] = React.useState();
  const helperTestClasses = helperTextStyles();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  React.useEffect(() => {
    console.log("file", file);
    if (file) {
      setDisableDownload(false);
    } else {
      setDisableDownload(true);
    }
  }, [file]);
  const [disableDownload, setDisableDownload] = React.useState(true);
  const [data, setData] = React.useState([]);

  const classes = useStyles();
  const nodeRef = React.useRef(null);

  const UploadData = async () => {
    const formData = new FormData();
    console.log("file", file);
    formData.append("file", file);
    let URL = "";
    if (Type === "KSBT") {
      URL = "/api/master/upload_ksbt";
    }
    if (Type === "Material") {
      URL = "/api/master/upload_material";
    }
    if (Type === "BOM") {
      URL = "/api/bom/upload_bom";
    }
    if (Type === "Routing") {
      URL = "/api/master/upload_routing";
    }
    let abortController = new AbortController();
    let signal = abortController.signal;

    (async () => {
      await api
        .post(
          URL,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          { signal: signal }
        )
        .then((res) => {
          console.log(res);
          Toast.fire({
            icon: "success",
            title: `response: ${res.data.message} ✔️`,
          });
          setTimeout(() => {
            setFile();
            refresh("");
            setDisableDownload(false);
          }, 1000);
        })
        .catch((err) => {
          if (!abortController.signal.aborted) {
            setDisableDownload(true);
            Toast.fire({
              icon: "error",
              title: `Failed to Upload Data: ${err.response.data.message} ❌`,
            });
          }
          setDisableDownload(true);
          Toast.fire({
            icon: "error",
            title: `Failed to Upload Data: ${err.response.data.message} ❌`,
          });
          console.log(err);
        });
    })();
  };
  const onResetValues = () => {
    setTitle("Upload File");

    setDisableDownload(true);
    handleClose();
  };

  const submitDownload = () => {
    UploadData();
    setTimeout(() => {
      onResetValues();
    }, 1000);
  };
  const getvalue = (event) => {
    console.log("file", event);
    setFile(event.target.files[0]);
    if (event.target.files[0] === undefined) {
      setTitle("Upload File");
    } else {
      setTitle(event.target.files[0].name);
    }
  };
  return (
    <Dialog
      PaperProps={{ style: { overflowY: "visible" } }}
      classes={{
        paperFullWidth: classes.paperFullWidth,
        dialogPaper: classes.paperFullWidth,
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
        paper: classes.paper,
      }}
      onClose={onResetValues}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogTitle id="customized-dialog-title" onClose={onResetValues}>
        Upload Data
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onResetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ overflowY: "visible" }}
        classes={{
          root: classes.dialogContentRoot,
          dialogPaper: classes.paperFullWidth,
        }}
        dividers
      >
        {/* <div className="relative mt-2 rounded-md shadow-sm "> */}
        <div className="flex ">
          <TextField
            // sx={{ minWidth: "350px" }}
            style={{
              width: "600px",
            }}
            id="upload-photo"
            label={title}
            variant="outlined"
            disabled
            fullWidth
            FormHelperTextProps={{ classes: helperTestClasses }}
          />

          {/* </div> */}

          <Button
            style={{
              backgroundColor: "#F7D716",
              marginTop: "0px",
              marginLeft: "5px",
              fontSize: "12px",
              textAlign: "Center",
              fontWeight: "bold",
              width: "300px",
            }}
            color="secondary"
            size="small"
            component="label"
            aria-label="add"
            variant="extended"
          >
            Browse File
            <input
              sx={{
                display: "none",
              }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              hidden
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              required
              label="Upload Photo"
              onChange={(e) => getvalue(e)}
            />
          </Button>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "1rem 1.5rem",
        }}
      >
        <Button
          autoFocus
          onClick={onResetValues}
          color="error"
          variant="outlined"
          endIcon={<ClearIcon />}
          style={{
            marginRight: "1rem",
          }}
        >
          cancel
        </Button>
        <Button
          style={{
            marginLeft: "1rem",
          }}
          autoFocus
          onClick={(e) => {
            // exportToExcel(data, "data");
            submitDownload();
          }}
          disabled={disableDownload}
          color="primary"
          variant="outlined"
          endIcon={<BackupIcon />}
        >
          Upload File
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UploadFile;
