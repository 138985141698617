import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { reset_password, logout, update_user } from "../../actions/auth";
import { FailedModal } from "../Modals";
import Swal from "sweetalert2";

export default function CardSettings() {
  const { message } = useSelector((state) => state.Message);
  const dispatch = useDispatch();
  const {
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);

  const [failedModal, setFailedModal] = useState(false);
  const { user } = useSelector((state) => state.Auth);
  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };
  const closeModal = () => {
    setFailedModal(false);
  };
  const handleConfirmPasswordClick = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };
  const initialValues = {
    email: user.email,
    username: user.username,
    fullname: user.fullname,
    role: user.role,
    user_id: user.user_id,
    password_current: "",
    password_new: "",
  };
  const [data, setData] = useState(initialValues);
  const resetData = () => {
    setData(initialValues);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleUpdateProfile = (e) => {
    e.preventDefault();
    dispatch(
      update_user(
        data.user_id,
        data.username,
        data.email,
        data.role,
        data.fullname
      )
    )
      .then(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "User Updated Successfully",
        });
      })
      .catch((err) => {
        console.log("error");
        setFailedModal(true);
        setTimeout(() => {
          resetData();
          closeModal();
        }, 3000);
      });
  };
  const handleResetPassword = (e) => {
    e.preventDefault();
    dispatch(
      reset_password(data.user_id, data.password_current, data.password_new)
    )
      .then(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Password Change Successfully,Please Login Again",
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            dispatch(logout());
          }
        });
      })
      .catch((err) => {
        console.log("error");
        setFailedModal(true);
        setTimeout(() => {
          resetData();
          closeModal();
        }, 3000);
      });
  };
  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded-lg bg-blueGray-100 border-0">
        {
          <FailedModal
            open={failedModal}
            closeModal={closeModal}
            title={"FAILED"}
            message={message}
          />
        }
        <form onSubmit={handleUpdateProfile}>
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">
                My account
              </h6>
              <button
                className="bg-blue-200 text-gray active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Change
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={data.username}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={data.email}
                    pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Full name
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    placeholder="full name"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={data.fullname}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr className="mt-6 border-b-1 border-blueGray-300" />
      </div>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <form name="contactform" onSubmit={handleResetPassword}>
          <div>
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <div className="rounded-t bg-white mb-0 px-4 py-4">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">
                  Change Password
                </h6>
                <button
                  className="bg-blue-200 text-gray active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Change
                </button>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-6">
                <div className="relative w-full mb-3 mt-2">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Current Password
                  </label>
                  <div className="absolute flex inset-y-0 items-center mt-6 pl-4 text-gray-400">
                    {passwordEye === false ? (
                      <FaEye onClick={handlePasswordClick} />
                    ) : (
                      <FaEyeSlash onClick={handlePasswordClick} />
                    )}
                  </div>
                  <input
                    className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                    placeholder="Password...."
                    type={passwordEye === false ? "password" : "text"}
                    name="password_current"
                    value={data.password_current}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3 mt-2">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    New Password
                  </label>
                  {/* eye section */}
                  <div className="absolute items-center mt-2 pl-4 text-gray-400">
                    {confirmPasswordEye === false ? (
                      <FaEye onClick={handleConfirmPasswordClick} />
                    ) : (
                      <FaEyeSlash onClick={handleConfirmPasswordClick} />
                    )}
                  </div>
                  <input
                    className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                    placeholder="New Password...."
                    type={confirmPasswordEye === false ? "password" : "text"}
                    name="password_new"
                    value={data.password_new}
                    onChange={handleChange}
                    required
                  />
                  {errors.confirmPassword && (
                    <span className="flex flex-col text-sm text-red-500">
                      {errors.confirmPassword.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
