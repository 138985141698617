import {
  red,
  pink,
  purple,
  green,
  teal,
  amber,
  brown,
  yellow,
  lightGreen,
  lime,
} from "@mui/material/colors";

export const columnData = [
  {
    id: "data",
    header: "Details SKU",
    columns: [
      {
        accessorKey: "plant",
        header: "Plant",
        size: 20,
        enableColumnActions: false,
        enableColumnOrdering: false,

        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "sku",
        header: "SKU",
        size: 20,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "sku_name",
        header: "SKU Name",
        size: 20,
        enableColumnOrdering: false,
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    id: "current",
    header: "Current",
    muiTableHeadCellProps: {
      align: "center",
      sx: {
        color: red[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },

    columns: [
      {
        accessorKey: "pm_current",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_current",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_current",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_current",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_current",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_current",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_current",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_current",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "Januari",
    header: "Januari",
    muiTableHeadCellProps: {
      align: "center",
      sx: {
        color: red[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },

    columns: [
      {
        accessorKey: "pm_januari",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_januari",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_januari",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_januari",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_januari",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_januari",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_januari",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_januari",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "februari",
    header: "Februari",
    muiTableHeadCellProps: {
      sx: {
        color: yellow[800],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_februari",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_februari",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_februari",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_februari",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_februari",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_februari",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_februari",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_februari",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "maret",
    header: "Maret",
    muiTableHeadCellProps: {
      sx: {
        color: "blue",
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_maret",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_maret",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_maret",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_maret",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_maret",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_maret",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_maret",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_maret",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "april",
    header: "April",
    muiTableHeadCellProps: {
      sx: {
        color: "pink",
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_april",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_april",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_april",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_april",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_april",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_april",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_april",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_april",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "mei",
    header: "Mei",
    muiTableHeadCellProps: {
      sx: {
        color: teal[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_mei",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_mei",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_mei",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_mei",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_mei",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_mei",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_mei",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_mei",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "juni",
    header: "Juni",
    muiTableHeadCellProps: {
      sx: {
        color: amber[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_juni",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_juni",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_juni",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_juni",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_juni",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_juni",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_juni",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_juni",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "juli",
    header: "Juli",
    muiTableHeadCellProps: {
      sx: {
        color: brown[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_juli",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_juli",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_juli",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_juli",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_juli",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_juli",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_juli",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_juli",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "agustus",
    header: "Agustus",
    muiTableHeadCellProps: {
      sx: {
        color: purple[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_agustus",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_agustus",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_agustus",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_agustus",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_agustus",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_agustus",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_agustus",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_agustus",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "september",
    header: "September",
    muiTableHeadCellProps: {
      sx: {
        color: pink[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_september",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_september",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_september",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_september",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_september",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_september",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_september",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_september",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "oktober",
    header: "Oktober",
    muiTableHeadCellProps: {
      sx: {
        color: green[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_oktober",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_oktober",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_oktober",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_oktober",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_oktober",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_oktober",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_oktober",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_oktober",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "november",
    header: "November",
    muiTableHeadCellProps: {
      sx: {
        color: lime[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_november",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_november",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_november",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_november",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_november",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_november",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_november",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_november",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  {
    id: "desember",
    header: "Desember",
    muiTableHeadCellProps: {
      sx: {
        color: lightGreen[500],
        backgroundColor: "white",
        borderRight: " solid #e0e0e0",
        alignItems: "center",
        borderTop: " solid #e0e0e0",
        // "& .Mui-TableHeadCell-Content-Labels": {
        //   padding: "0px",
        // },
        "& .MuiBox-root": {
          paddingLeft: "0px",
        },
      },
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "pm_desember",
        header: "Packaging Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "rm_desember",
        header: "Raw Material 📦",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "lh_desember",
        header: "Labour 👷",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "el_desember",
        header: "Electricity ⚡",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "gs_desember",
        header: "Gas⛽",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "dp_desember",
        header: "Depreciation📈",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "ot_desember",
        header: "OMC 🏭",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "cogm_desember",
        header: "COGM 💰",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
];

export const visibility = {
  pm_current: false,
  rm_current: false,
  lh_current: false,
  el_current: false,
  gs_current: false,
  dp_current: false,
  ot_current: false,
  pm_januari: false,
  rm_januari: false,
  lh_januari: false,
  el_januari: false,
  gs_januari: false,
  dp_januari: false,
  ot_januari: false,
  pm_februari: false,
  rm_februari: false,
  lh_februari: false,
  el_februari: false,
  gs_februari: false,
  dp_februari: false,
  ot_februari: false,
  pm_maret: false,
  rm_maret: false,
  lh_maret: false,
  el_maret: false,
  gs_maret: false,
  dp_maret: false,
  ot_maret: false,
  pm_april: false,
  rm_april: false,
  lh_april: false,
  el_april: false,
  gs_april: false,
  dp_april: false,
  ot_april: false,
  pm_mei: false,
  rm_mei: false,
  lh_mei: false,
  el_mei: false,
  gs_mei: false,
  dp_mei: false,
  ot_mei: false,
  pm_juni: false,
  rm_juni: false,
  lh_juni: false,
  el_juni: false,
  gs_juni: false,
  dp_juni: false,
  ot_juni: false,
  pm_juli: false,
  rm_juli: false,
  lh_juli: false,
  el_juli: false,
  gs_juli: false,
  dp_juli: false,
  ot_juli: false,
  pm_agustus: false,
  rm_agustus: false,
  lh_agustus: false,
  el_agustus: false,
  gs_agustus: false,
  dp_agustus: false,
  ot_agustus: false,
  pm_september: false,
  rm_september: false,
  lh_september: false,
  el_september: false,
  gs_september: false,
  dp_september: false,
  ot_september: false,
  pm_oktober: false,
  rm_oktober: false,
  lh_oktober: false,
  el_oktober: false,
  gs_oktober: false,
  dp_oktober: false,
  ot_oktober: false,
  pm_november: false,
  rm_november: false,
  lh_november: false,
  el_november: false,
  gs_november: false,
  dp_november: false,
  ot_november: false,
  pm_desember: false,
  rm_desember: false,
  lh_desember: false,
  el_desember: false,
  gs_desember: false,
  dp_desember: false,
  ot_desember: false,
};

export const columnDataKSBT = [
  {
    id: "data",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    header: "Details",
    columns: [
      {
        accessorKey: "cost_center",
        size: 50,
        header: "Cost Center",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "cost_center_description",
        header: "Description",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "activity_type",
        header: "Activity Type",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "activity_description",
        header: "Activity Description",
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
  },

  {
    id: "grouped",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    header: "Price 💲",
    columns: [
      {
        accessorKey: "januari",
        header: "Januari",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "februari",
        header: "Februari",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "maret",
        header: "Maret",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "april",
        header: "April",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "mei",
        header: "Mei",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "juni",
        header: "Juni",

        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "juli",
        header: "Juli",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "agustus",
        header: "Agustus",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "september",
        header: "September",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "oktober",
        header: "Oktober",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "november",
        header: "November",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        accessorKey: "desember",
        header: "Desember",
        enableColumnActions: false,
        enableSorting: false,
        enableGrouping: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
      },
    ],
  },
  //column definitions...
];

export const columnDataRouting = [
  {
    id: "data",
    header: "Details",
    columns: [
      {
        accessorKey: "cost_center",
        size: 50,
        header: "Cost Center",
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "plant",
        size: 30,
        header: "Plant",
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "material",
        size: 50,
        header: "Material",
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "material_name",
        size: 50,
        header: "Material Name",
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "kwh",
        size: 50,
        header: "KWH",
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "no_mc_mmbtu",
        size: 50,
        header: "No MC MMBTU",
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "noempl",
        size: 50,
        header: "No Employee",
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    id: "data",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    header: "Depreciation 📈",
    columns: [
      {
        accessorKey: "ZAP_DP_acttyp",
        size: 50,
        header: "Acctyp",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_DP_stdval",
        size: 50,
        header: "Stdval",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_DP_unit",
        size: 50,
        header: "Unit",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    id: "data",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    header: "Electricity ⚡",
    columns: [
      {
        accessorKey: "ZAP_EL_acttyp",
        size: 50,
        header: "Acctyp",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_EL_stdval",
        size: 50,
        header: "Stdval",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_EL_unit",
        size: 50,
        header: "Unit",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    id: "data",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    header: "Labour 👷",
    columns: [
      {
        accessorKey: "ZAP_LH_acttyp",
        size: 50,
        header: "Acctyp",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_LH_stdval",
        size: 50,
        header: "Stdval",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_LH_unit",
        size: 50,
        header: "Unit",
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    id: "datas",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    header: "GAS⛽",
    columns: [
      {
        accessorKey: "ZAP_GS_acttyp",
        size: 50,
        header: "Acctyp",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_GS_stdval",
        size: 50,
        header: "Stdval",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_GS_unit",
        size: 50,
        header: "Unit",
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,

        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    id: "datas",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    header: "OMC 🏭",
    columns: [
      {
        accessorKey: "ZAP_OT_acttyp",
        size: 50,
        header: "Acctyp",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_OT_stdval",
        size: 50,
        header: "Stdval",
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "ZAP_OT_unit",
        size: 50,
        header: "Unit",
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
  },
  //column definitions...
];

export const DataKSBT = (data) => {
  return data.map((el) => {
    let temItem = el;
    el.price.forEach((item) => {
      temItem = { ...temItem, [item.month]: item.price };
    });
    return temItem;
  });
};

const nullToStr = (v) => (v === null ? "" : v);
export const DataRouting = (data) => {
  return data.map((el) =>
    Object.fromEntries(
      Object.entries(el).flatMap(([key, val]) =>
        val && typeof val === "object"
          ? Object.entries(val).map(([k, v]) => [key + "_" + k, nullToStr(v)])
          : [[key, nullToStr(val)]]
      )
    )
  );
};
