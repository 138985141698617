import { Box } from "@mui/material";
import { DataGrid, gridClasses, GridActionsCellItem } from "@mui/x-data-grid";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataGridCustomToolbar from "../components/DataGridCustomToolbar";
import { alpha, styled } from "@mui/material/styles";
import api from "../configs/api";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { useDispatch } from "react-redux";
const CheckCogm = () => {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  let initialStates = {
    id: "1",
    material: "1212",
    bom_id: "1212",
    qty_material: "0",
    uom_material: "0",
  };
  const [loading, setLoading] = useState(true);
  const [SKU, setSKU] = useState(initialStates);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDialogBOM, setOpenDialogBOM] = useState(false);
  const [openDialogStatus, setOpenDialogStatus] = useState(false);
  const [openDialogAddComponent, setOpenDialogAddComponent] = useState(false);
  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(20);
  const [order, setOrder] = useState([
    {
      column: "15",
      dir: "desc",
    },
  ]);
  const [search, setSearch] = useState({
    value: "",
  });

  const [value, setValue] = React.useState("1");

  const [data, setData] = useState([]);
  const [status, setStatus] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/calculation/all_cogm_list",
          { page, limit, order, search },
          {
            signal: abortController.signal,
          }
        );

        if (data) {
          setData(data.data.data);
          setTotal(data.data.recordsFiltered);
          setStatus(data.status);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);

          setLoading(false);
        }
      }
    };
    fetchData();
    return () => abortController.abort();
  }, [page, limit, order, search]);

  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));
  const dataHeader = [
    "calculation_id",
    "plant",
    "sku",
    "sku_name",
    "month",
    "cost_pm",
    "cost_rm",
    "cost_lh",
    "cost_el",
    "cost_gs",
    "cost_dp",
    "cost_ot",
    "cogm",
    "status",
    "status_desc",
    "updated",
  ];
  const setDataOrder = (order) => {
    setLoading(true);
    const orders = order
      ? order
      : {
          field: "plant",
          dir: "asc",
        };
    setOrder([
      {
        column: String(dataHeader.indexOf(orders ? orders.field : "1")),
        dir: orders ? orders.sort : "asc",
      },
    ]);
  };
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
  });

  const usdPrice = {
    type: "number",
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    cellClassName: "font-tabular-nums",
  };
  const columns = [
    {
      field: "updated",
      headerName: "Updated",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "calculation_id",
      headerName: "Calculation ID",
      width: 200,
    },
    {
      field: "plant",
      headerName: "Plant",
      width: 80,
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 100,
    },
    { field: "sku_name", headerName: "SKU NAME", width: 300 },
    {
      field: "month",
      headerName: "Month",
      width: 100,
    },
    {
      field: "cogm",
      headerName: "COGM",
      align: "center",
      headerAlign: "center",
      ...usdPrice,
      width: 200,
    },
    {
      field: "cost_pm",
      headerName: "Cost PM",
      width: 100,
    },
    {
      field: "cost_rm",
      headerName: "Cost RM",
      width: 100,
    },
    {
      field: "cost_lh",
      headerName: "Cost LH",
      width: 100,
    },
    {
      field: "cost_el",
      headerName: "Cost EL",
      width: 100,
    },
    {
      field: "cost_gs",
      headerName: "Cost GS",
      width: 100,
    },
    {
      field: "cost_dp",
      headerName: "Cost DP",
      width: 100,
    },
    {
      field: "cost_ot",
      headerName: "Cost OT",
      width: 100,
    },
    {
      field: "status_desc",
      headerName: "Status Desc",
      width: 100,
    },
  ];
  const exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      api
        .post(
          "/api/calculation/all_cogm_list",
          {
            page,
            limit: 100000,
            order: [
              {
                column: "0",
                dir: "asc",
              },
            ],
            search: {
              value: "",
            },
          },
          {}
        )
        .then((response) => {
          console.log("response", response.data.data);

          exportToExcel(response.data.data.data, "COGM-Data");
          setLoading(false);
        });
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "failed",
        title: error.response.message,
      });
    }
  };
  return (
    <div className="bg-white">
      <div className="text-left pl-4 pb-4 font-bold text-3xl">
        <h2>Check COGM</h2>
        <Button
          disabled={loading}
          onClick={fetchData}
          style={{
            borderRadius: 5,
            backgroundColor: `${loading ? "#ccc" : "#2D3732"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<CloudDownloadIcon />}
        >
          Export Data
        </Button>
      </div>
      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="CHECK COGM"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1"></TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="flex flex-wrap text-justify mr-2 ml-2 pb-2">
        {/* <div className="w-full lg:w-9/12 overflow-auto"> */}
        <div className="w-full overflow-auto">
          <Box marginRight={"2px"}>
            <Box height="80vh">
              <StripedDataGrid
                // loading={isLoading || !data}
                getRowId={(row) => row.id}
                columns={columns}
                rowCount={(limit && total) || 0}
                rowsPerPageOptions={[20, 50, 100]}
                rows={data || []}
                pagination
                paginationMode="server"
                sortingMode="server"
                page={page}
                pageSize={limit}
                onPageSizeChange={(newPageSize) => (
                  setlimit(newPageSize), setLoading(true)
                )}
                sortModel={
                  order.length > 0
                    ? [
                        {
                          field: dataHeader[order[0].column],
                          sort: order[0].dir,
                        },
                      ]
                    : []
                }
                onPageChange={(newPage) => (setPage(newPage), setLoading(true))}
                onSortModelChange={(newSortModel) =>
                  setDataOrder(...newSortModel)
                }
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                components={{ Toolbar: DataGridCustomToolbar }}
                componentsProps={{
                  toolbar: { searchInput, setSearchInput, setSearch },
                }}
              />
            </Box>
          </Box>
        </div>
        {/* <div className="w-full lg:w-3/12 font-bold text-2xl rounded p-2 bg-gray-50 ">
          <div className="mb-2">
            <h1>Backgrounds Jobs</h1>
          </div>
          {status !== 200 ? (
            <div
              id="alert-additional-content-1"
              className="p-1 mb-1 border  border-red-300 rounded-lg bg-red-50 dark:bg-red-200"
              role="alert"
            >
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 mr-2 text-red-700 dark:text-red-800"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
                </svg>

                <h6 className="text-sm font-medium text-red-900">
                  BOM data failed to run
                  <p className="text-xs text-gray-500">
                    {new Date().toLocaleString() + ""}
                  </p>
                </h6>
              </div>
            </div>
          ) : (
            <div
              id="alert-additional-content-1"
              className={`p-1 mb-1 border ${
                loading
                  ? "border-blue-300 rounded-lg bg-blue-50 dark:bg-blue-200"
                  : " border-green-300 rounded-lg bg-green-50 dark:bg-green-200"
              }`}
              role="alert"
            >
              <div className="flex items-center">
                {loading ? (
                  <CircularProgress size="1.5rem" color="info" />
                ) : (
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 mr-2 text-green-700 dark:text-green-800"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.219,1.688c-4.471,0-8.094,3.623-8.094,8.094s3.623,8.094,8.094,8.094s8.094-3.623,8.094-8.094S14.689,1.688,10.219,1.688 M10.219,17.022c-3.994,0-7.242-3.247-7.242-7.241c0-3.994,3.248-7.242,7.242-7.242c3.994,0,7.241,3.248,7.241,7.242C17.46,13.775,14.213,17.022,10.219,17.022 M15.099,7.03c-0.167-0.167-0.438-0.167-0.604,0.002L9.062,12.48l-2.269-2.277c-0.166-0.167-0.437-0.167-0.603,0c-0.166,0.166-0.168,0.437-0.002,0.603l2.573,2.578c0.079,0.08,0.188,0.125,0.3,0.125s0.222-0.045,0.303-0.125l5.736-5.751C15.268,7.466,15.265,7.196,15.099,7.03"></path>
                  </svg>
                )}

                <span className="sr-only">Info</span>
                <h6
                  className={`text-sm font-medium ${
                    loading ? "text-blue-900" : "text-green-900"
                  }`}
                >
                  {loading
                    ? "BOM data running"
                    : `BOM data success running (Total: ${total})`}

                  <p className="text-xs text-gray-500">
                    {new Date().toLocaleString() + ""}
                  </p>
                </h6>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};
export default CheckCogm;
