import { combineReducers } from "redux";
import authReducer from "./auth";
import messageReducer from "./message";
import dataReducer from "./data";

import statusReducer from "./status";

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  Auth: authReducer,
  Message: messageReducer,
  Data: dataReducer,
  Status: statusReducer,
});

export default rootReducer;
