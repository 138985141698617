import { FaUser, FaLock, FaUserLock, FaRegIdCard } from "react-icons/fa";
import logo_nabati from "../../assets/logo-nabati.svg";
import { connect } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { login } from "../../actions/auth";

import { WarnModal, SuccessModal } from "../../components/Modals";

import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";
import { CLEAR_MESSAGE } from "../../actions/types";

const Login = () => {
  const error =
    "Please Make Sure You Have Entered The Correct Username or Password that you used to register. Otherwise change your password if you have forgotten it.";
  let navigate = useNavigate();

  const checkBtn = useRef();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = AuthService.token();
  });

  const { isLoggedIn } = useSelector((state) => state.Auth);
  const { message } = useSelector((state) => state.Message);
  const initialValues = { email: "", password: "" };
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(initialValues);
  const [successModal, setSuccessModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const dispatch = useDispatch();
  const closeModal = () => {
    setOpen(false);
    setSuccessModal(false);
  };
  const resetData = () => {
    setData(initialValues);
  };
  const handleLogin = (e) => {
    e.preventDefault();

    dispatch(login(data.email, data.password))
      .then(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Signed in successfully",
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            dispatch({ type: CLEAR_MESSAGE });
            navigate("/");
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        setOpen(true);
        setTimeout(() => {
          resetData();
          closeModal();
          dispatch({ type: CLEAR_MESSAGE });
        }, 3000);
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      {/* container */}
      <div className="bg-gradient-to-r from-yellow-500 block h-screen items-center justify-center p-4 pt-20 md:flex">
        {
          <WarnModal
            open={open}
            closeModal={closeModal}
            username={data.email}
            title={message}
            error={error}
          />
        }
        {
          <SuccessModal
            open={successModal}
            closeModal={closeModal}
            username={"YA"}
            title={"SUCCESS"}
          />
        }
        {/* login.card */}
        <div className=" bg-no-repeat bg-left bg-image flex flex-col items-center max-w-screen-lg overflow-hidden rounded-lg shadow-lg text-white w-full md:flex-row">
          {/* logo */}
          <div className="backdrop-blur-sm backdrop-filter flex flex-col items-center justify-center p-4 text-dark w-full md:w-1/2">
            <h1 className="font-medium text-3xl">COGM Calculation</h1>
            <p className="italic text-lg">For Manufacturing Cost Controller</p>
          </div>
          {/* form */}
          <div className="bg-white flex flex-col items-center p-4 space-y-8 w-full md:w-1/2">
            {/* title */}
            <div className="flex flex-col items-center">
              <h1 className="font-medium text-yellow-500 text-xl">Welcome</h1>{" "}
              <img
                className="m-2"
                src={logo_nabati}
                width={123}
                height={75}
                alt="logo Nabati"
              />
              <p className="text-gray-600">
                <strong>Login to COGM Page</strong>
              </p>
            </div>
            {/* input */}
            <form
              name="contactform"
              className="flex flex-col items-center space-y-3 pt-0"
              onSubmit={handleLogin}
            >
              <div className="relative">
                <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400">
                  <FaUser></FaUser>
                </span>
                <input
                  className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                  placeholder="Username or Email"
                  type="text"
                  name="email"
                  value={data.email}
                  // pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                  onChange={handleChange}
                  required
                ></input>
              </div>

              <div className="relative">
                <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400">
                  <FaLock></FaLock>
                </span>
                <input
                  className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                  placeholder="Password...."
                  type="password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                  required
                ></input>
              </div>

              <div className="items-left">
                <button
                  className="bg-yellow-500 animate-pulse font-medium inline-flex items-center px-3 py-1 rounded-md shadow-sm text-white pr-100"
                  type="submit"
                  ref={checkBtn}
                >
                  <FaUserLock className="mr-2"></FaUserLock>
                  Sign In
                </button>{" "}
                <Link to="/register">
                  <button
                    className="bg-yellow-500 font-medium inline-flex items-center px-3 py-1 rounded-md shadow-sm text-white pr-100"
                    type="submit"
                    ref={checkBtn}
                  >
                    <FaRegIdCard className="mr-2"></FaRegIdCard>
                    Register
                  </button>
                </Link>
              </div>
              <div className="mt-0 grid grid-cols-2 gap-4">
                <div className="flex flex-col items-center text-yellow-500">
                  <Link className="italic" to={"/reset"}>
                    Forget password?
                  </Link>
                </div>

                {/* <div className="flex flex-col items-center text-yellow-500">
                <Link className="italic" to={"/register"}>
                  Register
                </Link>
              </div> */}
              </div>
            </form>
            {/* button Link */}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  test: state.Auth,
});
export default connect(mapStateToProps)(Login);
