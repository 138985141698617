import { useState, useRef } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";
import PaidIcon from "@mui/icons-material/Paid";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import "react-spinner-animated/dist/index.css";
import { getMaterials, createMaterial } from "../../actions/data";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import {
  Button,
  createTheme,
  Box,
  Tooltip,
  Autocomplete,
  ThemeProvider,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import { useEffect, useMemo, useCallback } from "react";
import { set } from "react-hook-form";

const CreateMaterial = (props) => {
  console.log(props.data);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const plant = [
    { id: 1, label: "J103" },
    { id: 2, label: "K103" },
    { id: 3, label: "K104" },
    { id: 4, label: "K122" },
    { id: 5, label: "K124" },
    { id: 6, label: "K130" },
    { id: 7, label: "K131" },
    { id: 8, label: "K132" },
    { id: 9, label: "K135" },
    { id: 10, label: "K138" },
    { id: 11, label: "S104" },
    { id: 12, label: "S110" },
    { id: 13, label: "S112" },
    { id: 14, label: "S118" },
    { id: 15, label: "S115" },
    { id: 16, label: "K116" },
    { id: 17, label: "K118" },
  ];
  const type = [
    {
      id: 1,
      label: "pm",
    },
    {
      id: 2,
      label: "rm",
    },
    {
      id: 3,
      label: "sfg",
    },
    {
      id: 4,
      label: "premix",
    },
  ];
  const dispatch = useDispatch();
  const globalTheme = useTheme();
  const [loading, setLoading] = useState(false);
  const [valuess, setValues] = useState({
    material: "XCAS-New",
    material_description: "New Material",
    type: "",
    plant: "",
    januari: 0,
    februari: 0,
    maret: 0,
    april: 0,
    mei: 0,
    juni: 0,
    juli: 0,
    agustus: 0,
    september: 0,
    oktober: 0,
    november: 0,
    desember: 0,
  });

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
  };

  const { message } = useSelector((state) => state.Message);

  const handleChange = (e) => {
    if (
      (e.target.value === "" ||
        e.target.value === null ||
        e.target.value < 0) &&
      e.target.type === "number"
    ) {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: parseFloat(0) });
    } else if (e.target.type === "text") {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: value });
    } else {
      const { name, value } = e.target;
      setValues({ ...valuess, [name]: parseFloat(value) });
    }
  };
  const resetValues = () => {
    setValues({
      material: "XCAS-New",
      material_description: "New Material",
      type: "",
      plant: "",
      januari: 0,
      februari: 0,
      maret: 0,
      april: 0,
      mei: 0,
      juni: 0,
      juli: 0,
      agustus: 0,
      september: 0,
      oktober: 0,
      november: 0,
      desember: 0,
      noempl: 0,
    });
    props.closeModal();
  };

  //Create BOM
  const submitData = (e) => {
    e.preventDefault();
    console.log({
      material: valuess.material,
      material_description: valuess.material_description,
      type: valuess.type,
      plant: valuess.plant,
      price: [
        {
          date: "januari",
          price: valuess.januari,
        },
        {
          date: "februari",
          price: valuess.februari,
        },
        {
          date: "maret",
          price: valuess.maret,
        },
        {
          date: "april",
          price: valuess.april,
        },
        {
          date: "mei",
          price: valuess.mei,
        },
        {
          date: "juni",
          price: valuess.juni,
        },
        {
          date: "juli",
          price: valuess.juli,
        },
        {
          date: "agustus",
          price: valuess.agustus,
        },
        {
          date: "september",
          price: valuess.september,
        },
        {
          date: "oktober",
          price: valuess.oktober,
        },
        {
          date: "september",
          price: valuess.september,
        },
        {
          date: "november",
          price: valuess.november,
        },
        {
          date: "desember",
          price: valuess.desember,
        },
      ],
    });
    const data = {
      material: valuess.material,
      material_description: valuess.material_description,
      type: valuess.type,
      plant: valuess.plant,
      price: [
        {
          date: "januari",
          price: valuess.januari,
        },
        {
          date: "februari",
          price: valuess.februari,
        },
        {
          date: "maret",
          price: valuess.maret,
        },
        {
          date: "april",
          price: valuess.april,
        },
        {
          date: "mei",
          price: valuess.mei,
        },
        {
          date: "juni",
          price: valuess.juni,
        },
        {
          date: "juli",
          price: valuess.juli,
        },
        {
          date: "agustus",
          price: valuess.agustus,
        },
        {
          date: "september",
          price: valuess.september,
        },
        {
          date: "oktober",
          price: valuess.oktober,
        },
        {
          date: "september",
          price: valuess.september,
        },
        {
          date: "november",
          price: valuess.november,
        },
        {
          date: "desember",
          price: valuess.desember,
        },
      ],
    };

    dispatch(createMaterial(data))
      .then(() => {
        Toast.fire({
          icon: "success",
          title: `Success to Create Material✔️`,
        });

        resetValues();
        props.refreshData(valuess.material);
      })
      .catch((err) => {
        resetValues();
        Toast.fire({
          icon: "error",
          title: `Failed to Update Create Material ❌`,
        });
      });
  };

  return (
    <Dialog
      style={{}}
      open={props.open}
      onClose={resetValues}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          position: "sticky",
        }}
      >
        Create New Material
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={resetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {/* <form onSubmit={submitData}> */}
      <DialogContent dividers>
        <DialogContentText>Add Material Details:</DialogContentText>
        <TextField
          margin="normal"
          variant="standard"
          label="Material"
          name="material"
          sx={{
            marginRight: 2,
          }}
          value={valuess.material}
          onChange={handleChange}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="Material Description"
          name="material_description"
          sx={{
            marginRight: 2,
          }}
          value={valuess.material_description}
          onChange={handleChange}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChangeCircleIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <Box
          style={{
            alignItems: "center",
            marginRight: 60,

            marginTop: 10,
          }}
        >
          <Autocomplete
            id="combo-box-demo"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={plant}
            onChange={(event, newValue) => {
              const value = newValue ? newValue.label : "";
              setValues({
                ...valuess,
                plant: value,
              });
            }}
            renderInput={(params) => <TextField {...params} label="Plant" />}
          />
        </Box>
        <Box
          style={{
            alignItems: "center",
            marginRight: 60,

            marginTop: 10,
          }}
        >
          <Autocomplete
            id="combo-box-demo"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={type}
            onChange={(event, newValue) => {
              const value = newValue ? newValue.label : "";
              setValues({
                ...valuess,
                type: value,
              });
            }}
            renderInput={(params) => <TextField {...params} label="Type" />}
          />
        </Box>
        <DialogContentText
          style={{
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          Add Material Price:
        </DialogContentText>
        <TextField
          margin="normal"
          variant="standard"
          label="januari"
          name="januari"
          sx={{
            marginRight: 2,
          }}
          value={valuess.januari}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="februari"
          name="februari"
          sx={{
            marginRight: 2,
          }}
          value={valuess.februari}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="maret"
          name="maret"
          sx={{
            marginRight: 2,
          }}
          value={valuess.maret}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="april"
          name="april"
          sx={{
            marginRight: 2,
          }}
          value={valuess.april}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="mei"
          name="mei"
          sx={{
            marginRight: 2,
          }}
          value={valuess.mei}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="juni"
          name="juni"
          sx={{
            marginRight: 2,
          }}
          value={valuess.juni}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="juli"
          name="juli"
          sx={{
            marginRight: 2,
          }}
          value={valuess.juli}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="agustus"
          name="agustus"
          sx={{
            marginRight: 2,
          }}
          value={valuess.agustus}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="september"
          name="september"
          sx={{
            marginRight: 2,
          }}
          value={valuess.september}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="oktober"
          name="oktober"
          sx={{
            marginRight: 2,
          }}
          value={valuess.oktober}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="november"
          name="november"
          sx={{
            marginRight: 2,
          }}
          value={valuess.november}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          margin="normal"
          variant="standard"
          label="desember"
          name="desember"
          sx={{
            marginRight: 2,
          }}
          value={valuess.desember}
          onChange={handleChange}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PaidIcon />
              </InputAdornment>
            ),
          }}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            valuess.material === "" ||
            valuess.material_description === "" ||
            valuess.type === "" ||
            valuess.plant === ""
              ? true
              : false
          }
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "green",
            },
          }}
          onClick={submitData}
          endIcon={<PublishedWithChangesIcon />}
        >
          Submit
        </Button>
      </DialogActions>
      {/* </form> */}
    </Dialog>
  );
};

export default CreateMaterial;
