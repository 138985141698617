import React from "react";
import Logo_Nabati from "../../assets/logo-nabati.svg";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
// components

export default function CardProfile() {
  const dispatch = useDispatch();
  const logouts = () => {
    dispatch(logout());
  };
  const { user } = useSelector((state) => state.Auth);
  return (
    <div>
      <div className="relative  flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-10">
        <div className="px-6">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4 flex justify-center">
              <div className="relative">
                <img
                  alt="..."
                  src={Logo_Nabati}
                  // className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                />
              </div>
            </div>
          </div>
          <div className="text-center mt-12">
            <h3 className="text-xl font-semibold leading-normal  text-blueGray-700 mb-2">
              {user.fullname}
            </h3>
            <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
              Bandung, West Java
            </div>
            <div className="mb-2 text-blueGray-600 mt-10">
              <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
              {user.email === "jansenstan24@gmail.com"
                ? "Management Trainee"
                : "COGM"}
            </div>
            <div className="mb-2 text-blueGray-600">
              <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
              PT KALDU SARI NABATI INDONESIA
            </div>
          </div>
          <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <a
                  href="/"
                  className="font-normal text-lightBlue-500"
                  onClick={(e) => logouts()}
                >
                  LOG OUT
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
