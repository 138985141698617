import * as React from "react";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import CardCalculation from "../components/Cards/CardCalculation";
import dataService from "../services/data.service";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: "darkslategray",
  backgroundColor: "aliceblue",
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const Calculation = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const swalWithBootstrapButtons = Swal.mixin({
    // customClass: {
    //   confirmButton: "btn btn-success",
    //   cancelButton: "btn btn-danger",
    // },
    // buttonsStyling: false,
  });
  const [sku, setSku] = React.useState("");
  const [dataSKU, setDataSKU] = React.useState([]);
  const [dataSpec, setDataSpec] = React.useState([]);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    let abortController;
    (async () => {
      abortController = new AbortController();
      let signal = abortController.signal;
      await dataService
        .getAllSKU(signal)
        .then((response) => {
          setDataSKU(response.data.data);
        })
        .catch((error) => {
          setShow(false);
        });
    })();
    return () => abortController.abort();
  }, []);
  const AllSkuGet = async () => {
    console.log("data-Jalan1ss");
    await dataService
      .getAllSKU()
      .then((response) => {
        setDataSKU(response.data.data);
      })
      .catch((error) => {
        setShow(false);
        console.log(error);
      });
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && sku !== "" && sku !== null) {
      dataService.getBomSku(sku.sku).then((response) => {
        setDataSpec(response.data.data);
        setTimeout(() => {
          setShow(true);
        }, 500);
      });
    } else {
      setShow(false);
    }
  };

  const options = dataSKU.map((option) => {
    const firstLetter = option.sku_name[0].toUpperCase();

    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const dataSKUList = async () => {
    if (sku !== "" && sku !== null) {
      dataService.getBomSku(sku.sku).then((response) => {
        setDataSpec(response.data.data);
        setTimeout(() => {
          setShow(true);
        }, 500);
      });
    } else {
      console.log("data-Jalan2");
      setShow(false);
    }
  };

  const handleSetSKU = (e) => {
    if (e !== "" && e !== null) {
      setSku(e);
    } else {
      setSku(e);
      setShow(false);
    }
  };
  const CalculateAllSKU = async () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "This will calculate all SKU in the system! and it will take a few moments",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Start it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dataService
            .calculateAllData()
            .then((response) => {
              Toast.fire({
                icon: "success",
                title: `${response.data.message} with id: ${response.data.data.calculation_id} `,
              });
            })
            .catch((error) => {
              Toast.fire({
                icon: "error",
                title: error.response.data.message,
              });
            });
        }
      });
  };
  let component = dataSpec.flatMap((elem) => elem.component);
  let KSBT = dataSpec.flatMap((elem) => elem.ksbt);
  let Routing = dataSpec.flatMap((elem) => elem.routing);

  return (
    <div className=" m-2 p-2 mr-2 bg-white  ">
      <div className="text-left pl-2 pb-4 font-bold text-3xl">
        <h2>Find Material</h2>
        <Button
          // disabled={loading}
          onClick={() => {
            CalculateAllSKU();
          }}
          style={{
            borderRadius: 5,
            backgroundColor: "#428777",
            // backgroundColor: `${isloading ? "#ccc" : "#428777"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          // endIcon={<BackupIcon />}
        >
          Calculate All SKU
        </Button>
      </div>
      <div className="p-2 align-left">
        <form
          className="flex items-center"
          onSubmit={(e) => {
            e.preventDefault();
            dataSKUList();
          }}
        >
          <Autocomplete
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            style={{ width: "100%", color: "#ffffff" }}
            onChange={(event, newValue) => {
              handleSetSKU(newValue);
            }}
            id="grouped-demo"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) =>
              option.sku_name + `, code:(${option.sku})`
            }
            sx={{ width: 300 }}
            renderInput={(params) => {
              params.inputProps.onKeyDown = handleKeyDown;
              return <TextField {...params} label="Search by SKU" required />;
            }}
            renderGroup={(params) => (
              <ul key={params.key}>
                <li>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              </ul>
            )}
          />
          <div>
            <Button
              type="submit"
              color="success"
              variant="contained"
              style={{
                marginLeft: 10,
                width: "100%",
                height: 54,
              }}
              startIcon={<ContentPasteSearchIcon />}
            >
              Search
            </Button>
          </div>
        </form>
      </div>
      <div className="bg-white scrollbar-hide">
        {show ? (
          <div className="">
            <CardCalculation
              component={component}
              KSBT={KSBT}
              SKU={dataSpec}
              data={sku}
              routing={Routing}
              dataSKUList={AllSkuGet}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default Calculation;
