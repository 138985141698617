import { Box } from "@mui/material";
import { DataGrid, gridClasses, GridActionsCellItem } from "@mui/x-data-grid";
import api from "../../configs/api";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BackupIcon from "@mui/icons-material/Backup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DataGridCustomToolbar from "../../components/DataGridCustomToolbar";
import { UploadFile, UpdateKSBT } from "../../components/Modals";
import Swal from "sweetalert2";
import { alpha, styled } from "@mui/material/styles";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const KSBT = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  const [loading, setLoading] = useState(true);
  const [KSBT, setKSBT] = useState({});
  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="text"
          size="small"
          style={{
            marginLeft: 0,
            outline: "none",
            color: "#3f51b5",
            fontSize: "1.25rem",
          }}
          onClick={(event) => {
            console.log(event, params.row);
          }}
        >
          ...
        </Button>
      </strong>
    );
  };

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(20);
  const [order, setOrder] = useState([
    {
      column: "1",
      dir: "asc",
    },
  ]);
  const [search, setSearch] = useState({
    value: "",
  });
  const [openUpdate, setOpenUpdate] = useState(false);
  const [value, setValue] = React.useState("1");

  const [data, setData] = useState([]);

  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/master/list_ksbt",
          { page, limit, order, search },
          { signal: abortController.signal }
        );

        if (data) {
          setData(data.data.data);
          setTotal(data.data.recordsFiltered);
          setTimeout(() => {
            setLoading(false);
          }, 100);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);

          setLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, [page, limit, order, search]);

  const dataHeader = [
    "cost_center",
    "cost_ctr_short_text",
    "activity_type",
    "activity_short_text",
    "current_price",
  ];
  const setDataOrder = (order) => {
    if (order) {
      setLoading(true);
      return setOrder([
        {
          column: String(dataHeader.indexOf(order.field)),
          dir: order.sort,
        },
      ]);
    }
    setLoading(true);
    return setOrder([
      {
        column: "1",
        dir: "asc",
      },
    ]);
  };
  const transformed = data.map((el) => {
    const if_empty = [
      {
        januari: 0,
        februari: 0,
        maret: 0,
        april: 0,
        mei: 0,
        juni: 0,
        juli: 0,
        agustus: 0,
        september: 0,
        oktober: 0,
        november: 0,
        desember: 0,
      },
    ];
    let temItem = el;

    if (el.price.length > 1) {
      el.price.forEach((item) => {
        temItem = { ...temItem, [item.date]: item.price };
      });
    } else {
      temItem = { ...temItem, ...if_empty[0] };
    }

    delete temItem.price;

    return temItem;
  });

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CurrencyExchangeIcon />}
          label="Update Price"
          onClick={() => {
            openModalUpdate(params);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: "cost_center",
      headerName: "Cost Center",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "cost_ctr_short_text",
      headerName: "Cost Ctr Short Text",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "activity_type",
      headerName: "Activity Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "activity_short_text",
      headerName: "Activity Short Text",
      width: 200,
    },
    {
      field: "current_price",
      headerName: "Current Price",
      sortable: false,
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "januari",
      sortable: false,
      headerName: "Januari",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "februari",
      sortable: false,
      headerName: "Februari",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "maret",
      sortable: false,
      headerName: "Maret",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "april",
      sortable: false,
      headerName: "April",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "mei",
      sortable: false,
      headerName: "Mei",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "juni",
      sortable: false,
      headerName: "Juni",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "juli",
      sortable: false,
      headerName: "Juli",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "agustus",
      sortable: false,
      headerName: "Agustus",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "september",
      sortable: false,
      headerName: "September",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "oktober",
      sortable: false,
      headerName: "Oktober",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "november",
      sortable: false,
      headerName: "November",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
    {
      field: "desember",
      sortable: false,
      headerName: "Desember",
      width: 150,

      align: "left",
      valueFormatter: (params) =>
        params.value.toLocaleString?.("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      headerAlign: "center",
    },
  ];

  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));
  const exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      api
        .post(
          "/api/master/list_ksbt",
          {
            page,
            limit: 1000,
            order: [
              {
                column: "0",
                dir: "asc",
              },
            ],
            search: {
              value: "",
            },
          },
          {}
        )
        .then((response) => {
          console.log("response", response.data.data);
          const transformed = response.data.data.data.map((el) => {
            const if_empty = [
              {
                januari: 0,
                februari: 0,
                maret: 0,
                april: 0,
                mei: 0,
                juni: 0,
                juli: 0,
                agustus: 0,
                september: 0,
                oktober: 0,
                november: 0,
                desember: 0,
              },
            ];
            let temItem = el;

            if (el.price.length > 1) {
              el.price.forEach((item) => {
                temItem = { ...temItem, [item.date]: item.price };
              });
            } else {
              temItem = { ...temItem, ...if_empty[0] };
            }

            delete temItem.price;

            return temItem;
          });

          exportToExcel(transformed, "TARIF-Data");
          setLoading(false);
        });
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "failed",
        title: error.response.message,
      });
    }
  };
  const downloadExcel = () => {
    try {
      api
        .get("https://cogm.nabatisnack.co.id:3001/api/master/template/ksbt", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, "template-ksbt.xlsx");
        });
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "failed",
        title: error.response.message,
      });
    }
  };
  const [opemModal, setOpenModal] = useState(false);
  const openModal = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
    setOpenUpdate(false);
  };
  const openModalUpdate = (e) => {
    setKSBT({
      cost_center: e.row.cost_center,
      activity_type: e.row.activity_type,
    });
    setOpenUpdate(true);
  };
  const refresh = async (datas) => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/master/list_ksbt",
          {
            page,
            limit,
            order,
            search: {
              value: datas,
            },
          },
          { signal: abortController.signal }
        );

        if (data) {
          setData(data.data.data);
          setTotal(data.data.recordsFiltered);
          setTimeout(() => {
            setLoading(false);
          }, 100);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);

          setLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  };
  return (
    <div className="bg-white">
      {<UploadFile show={opemModal} handleClose={closeModal} Type="KSBT" />}
      {
        <UpdateKSBT
          open={openUpdate}
          closeModal={closeModal}
          KSBT={KSBT}
          refresh={refresh}
        />
      }
      <div className="text-left pl-2 pb-2 font-bold text-3xl">
        <h2>Master Data TARIF</h2>

        <Button
          disabled={loading}
          onClick={downloadExcel}
          style={{
            borderRadius: 5,
            backgroundColor: `${loading ? "#ccc" : "#F7D716"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<FileDownloadIcon />}
        >
          Template
        </Button>
        <Button
          // disabled={true}
          onClick={openModal}
          style={{
            borderRadius: 5,
            backgroundColor: `${loading ? "#ccc" : "#428777"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<BackupIcon />}
        >
          Upload Data
        </Button>
        <Button
          disabled={loading}
          onClick={fetchData}
          style={{
            borderRadius: 5,
            backgroundColor: `${loading ? "#ccc" : "#2D3732"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<CloudDownloadIcon />}
        >
          Export Data
        </Button>
      </div>

      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="KSBT DATA" value="1" style={{ color: "#FF0000" }} />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1"></TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="flex flex-wrap text-justify mr-2 ml-2 pb-2">
        <div className="w-full overflow-auto">
          <Box marginRight={"2px"}>
            <Box height="80vh">
              <StripedDataGrid
                loading={loading || !transformed}
                getRowId={(row) => row.id}
                columns={columns}
                rowCount={(limit && total) || 0}
                rowsPerPageOptions={[20, 50, 100]}
                rows={transformed || []}
                pagination
                paginationMode="server"
                sortingMode="server"
                page={page}
                pageSize={limit}
                onPageSizeChange={(newPageSize) => (
                  setlimit(newPageSize), setLoading(true)
                )}
                onPageChange={(newPage) => (setPage(newPage), setLoading(true))}
                onSortModelChange={(newSortModel) =>
                  setDataOrder(...newSortModel)
                }
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                components={{ Toolbar: DataGridCustomToolbar }}
                componentsProps={{
                  toolbar: { searchInput, setSearchInput, setSearch },
                }}
              />
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default KSBT;
