import { Navbar, Sidebar } from "../components";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
const Layout = ({ children }) => {
  const { isLoggedIn } = useSelector((state) => state.Auth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <div className="flex relative h-screen overflow-hidden scrollbar-hide bg-slate-100  ">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden scrollbar-hide">
          <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className="flex flex-col h-screen bg-slate-100 justify-between">
            <main>
              <div className=" flex-grow scrollbar-hide">
                {children}
                <Outlet />
              </div>
            </main>
            <footer className="flex justify-center pb-1 ">
              <div className="container mx-auto ">
                <hr className="mb-1 border-b-1 border-blueGray-200" />
                <div className="flex flex-wrap items-center  justify-center">
                  <div className="w-full md:w-4/12 ">
                    <div className="text-sm text-center text-blueGray-500 font-semibold py-1">
                      Copyright © {new Date().getFullYear() + " "}
                      <a
                        href="https://www.nabatisnack.co.id/"
                        className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                      >
                        Kaldu Sari Nabati Indonesia
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
      {/* <Sidebar />
      <div className="relative md:ml-60 flex flex-col min-h-screen">
        <Navbar />
        <div className="relative md:pt-32 pb-32 pt-12"></div>
        <div className="bg-white-100 white flex-grow -mt-44">
          {children}
          <Outlet />
        </div>
        <footer className="flex justify-center pb-1 ">
          <div className="container mx-auto ">
            <hr className="mb-1 border-b-1 border-blueGray-200" />
            <div className="flex flex-wrap items-center  justify-center">
              <div className="w-full md:w-4/12 ">
                <div className="text-sm text-center text-blueGray-500 font-semibold py-1">
                  Copyright © {new Date().getFullYear()}{" "}
                  <a
                    href="https://www.creative-tim.com"
                    className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                  >
                    Kaldu Sari Nabati Indonesia
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div> */}
    </>
  );
};

export default Layout;
