import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCESS,
} from "./types";
import TokenService from "../configs/token.service";
import AuthService from "../services/auth.service";

export const update_user =
  (user_id, username, email, role, fullname) => (dispatch) => {
    return AuthService.update_user(
      user_id,
      username,
      email,
      role,
      fullname
    ).then(
      (response) => {
        let user = {
          user_id: user_id,
          username: username,
          email: email,
          role: role,
          fullname: fullname,
        };
        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: { user: user },
        });
        TokenService.updateLocalUser(user);
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_PROFILE_FAILED,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
export const register_user = (data) => (dispatch) => {
  return AuthService.register(data).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error);
    }
  );
};
export const forgetPassword = (username) => (dispatch) => {
  return AuthService.forgetPassword(username).then(
    (response) => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: RESET_PASSWORD_FAILED,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const reset_password =
  (user_id, password_current, password_new) => (dispatch) => {
    return AuthService.resetpassword(
      user_id,
      password_current,
      password_new
    ).then(
      (response) => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
        });

        return Promise.resolve();
      },
      (error) => {
        console.log("reset", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: RESET_PASSWORD_FAILED,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
