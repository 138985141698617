import api from "../configs/api";
import axios from "axios";
import TokenService from "../configs/token.service";

const register = (data) => {
  return api.post("/api/auth/create_user", data);
};
const update_user = (user_id, username, email, role, fullname) => {
  return api.post("/api/auth/update_user", {
    user_id,
    username,
    email,
    role,
    fullname,
  });
};
const resetpassword = (user_id, password_current, password_new) => {
  return api.post("/api/auth/update_password", {
    user_id,
    password_current,
    password_new,
  });
};
const secretKey = {
  apps_id: process.env.REACT_APP_APPS_ID,
  secret_key: process.env.REACT_APP_API_KEY,
};

const token = (signal) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/token/generate_token`, secretKey, {
      signal: signal,
    })
    .then((response) => {
      if (response.data.data.token) {
        TokenService.setToken(response.data.data);
      }
      return response.data.data;
    });
};

const login = (username, password) => {
  return api
    .post("/api/auth/login", {
      username,
      password,
    })
    .then((response) => {
      TokenService.setUser(response.data.data);
      return response.data;
    });
};
const forgetPassword = (username) => {
  return api.post("/api/auth/forgot_password", { username });
};
const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  token,
  forgetPassword,
  update_user,
  resetpassword,
};

export default AuthService;
