import {
  DELETE_DATA_FAILED,
  DELETE_DATA_SUCCESS,
  SET_MESSAGE,
  UPDATE_DATA_FAILED,
  UPDATE_DATA_SUCCESS,
  GET_MATERIALS_FAILED,
  GET_MATERIALS_SUCCESS,
} from "./types";
import DataService from "../services/data.service";

export const deleteData = (bom_id) => (dispatch) => {
  return DataService.deleteData(bom_id).then(
    (response) => {
      dispatch({
        type: DELETE_DATA_SUCCESS,
        payload: { message: response.data.message },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_DATA_FAILED,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const updateQty = (bom_id, qty) => (dispatch) => {
  return DataService.updateQty(bom_id, qty).then(
    (response) => {
      dispatch({
        type: UPDATE_DATA_SUCCESS,
        payload: { message: response.data.message },
      });
      return Promise.resolve();
    },
    (error) => {
      console.log("update", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_DATA_FAILED,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const updateStatus = (bom_id, status) => (dispatch) => {
  return DataService.updateStatus(bom_id, status).then(
    (response) => {
      dispatch({
        type: UPDATE_DATA_SUCCESS,
        payload: { message: response.data.message },
      });
      return Promise.resolve();
    },
    (error) => {
      console.log("update", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_DATA_FAILED,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const updateStatusCalculation =
  (calculation_id, status) => (dispatch) => {
    return DataService.updateStatusCalculation(calculation_id, status).then(
      (response) => {
        dispatch({
          type: UPDATE_DATA_SUCCESS,
          payload: { message: response.data.message },
        });
        return Promise.resolve();
      },
      (error) => {
        console.log("update", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_DATA_FAILED,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const getMaterials = (plant) => (dispatch) => {
  return DataService.getMaterialData(plant).then(
    (response) => {
      console.log("data material", response);
      dispatch({
        type: GET_MATERIALS_SUCCESS,
        payload: { materials: response.data.data },
      });
      return Promise.resolve();
    },
    (error) => {
      console.log("update", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.data.message ||
        error.toString();

      console.log("message", message);
      dispatch({
        type: GET_MATERIALS_FAILED,
        payload: {
          message: message,
        },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: message,
        },
      });

      return Promise.reject();
    }
  );
};

export const create_bom = (data) => (dispatch) => {
  return DataService.createData(data).then(
    (response) => {
      console.log("response create BOM", response);

      return Promise.resolve();
    },
    (error) => {
      console.log("update", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const update_allowance = (data) => (dispatch) => {
  return DataService.updateAllowance(data).then(
    (response) => {
      console.log("update Allowance", response);
      dispatch({
        type: UPDATE_DATA_SUCCESS,
        payload: { message: response.data.message },
      });
      return Promise.resolve();
    },
    (error) => {
      console.log("update", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_DATA_FAILED,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const update_routing = (data) => (dispatch) => {
  return DataService.updateRouting(data).then(
    (response) => {
      console.log("update Allowance", response);
      dispatch({
        type: UPDATE_DATA_SUCCESS,
        payload: { message: response.data.message },
      });
      return Promise.resolve();
    },
    (error) => {
      console.log("update", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_DATA_FAILED,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const createMaterial = (data) => (dispatch) => {
  return DataService.createMaterial(data).then(
    (response) => {
      console.log("response create Material", response);

      return Promise.resolve();
    },
    (error) => {
      console.log("update", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.data.message ||
        error.toString();

      console.log("message", message);
      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: message,
        },
      });

      return Promise.reject();
    }
  );
};
