import { Box } from "@mui/material";
import { DataGrid, gridClasses, GridActionsCellItem } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataGridCustomToolbar from "../components/DataGridCustomToolbar";
import { alpha, styled } from "@mui/material/styles";
import api from "../configs/api";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import Swal from "sweetalert2";

import { useDispatch } from "react-redux";
import ChangeAllowance from "../components/Modals/ChangeAllowance";
const Allowance = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));

  const [loading, setLoading] = useState(true);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(20);
  const [order, setOrder] = useState([
    {
      column: "0",
      dir: "desc",
    },
  ]);
  const [search, setSearch] = useState({
    value: "",
  });

  const [value, setValue] = React.useState("1");

  const [data, setData] = useState([]);
  const [status, setStatus] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const closeModal = () => {
    setOpen(false);
    // setDatas([]);
  };
  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/master/list_waste_allowance",
          { page, limit, order, search },
          {
            signal: abortController.signal,
          }
        );

        if (data) {
          console.log(data);
          setData(data.data.data);
          setTotal(data.data.recordsFiltered);
          setStatus(data.status);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);

          setLoading(false);
        }
      }
    };
    fetchData();
    return () => abortController.abort();
  }, [page, limit, order, search]);

  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));
  const dataHeader = [
    "type",
    "januari",
    "februari",
    "maret",
    "april",
    "mei",
    "juni",
    "juli",
    "agustus",
    "september",
    "oktober",
    "november",
    "desember",
  ];
  const setDataOrder = (order) => {
    setLoading(true);
    const orders = order
      ? order
      : {
          field: "type",
          dir: "asc",
        };
    setOrder([
      {
        column: String(dataHeader.indexOf(orders ? orders.field : "0")),
        dir: orders ? orders.sort : "asc",
      },
    ]);
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<AutorenewIcon />}
          label="Edit Allowance"
          onClick={() => {
            setDatas(params.row);
            setOpen(true);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "januari",
      headerName: "Januari",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "current",
      headerName: "Current",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "februari",
      headerName: "Februari",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "maret",
      headerName: "Maret",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "april",
      headerName: "April",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mei",
      headerName: "Mei",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "juni",
      headerName: "Juni",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "juli",
      headerName: "Juli",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "agustus",
      headerName: "Agustus",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "september",
      headerName: "September",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "oktober",
      headerName: "Oktober",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "november",
      headerName: "November",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "desember",
      headerName: "Desember",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
  ];
  const refreshData = async () => {
    try {
      const { data } = await api.post("/api/master/list_waste_allowance", {
        page,
        limit,
        order,
        search,
      });

      if (data) {
        console.log(data);
        setData(data.data.data);
        setTotal(data.data.recordsFiltered);
        setStatus(data.status);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setData([]);

      setLoading(false);
    }
  };
  return (
    <div className="bg-white">
      {
        <ChangeAllowance
          data={datas}
          open={open}
          refreshData={refreshData}
          closeModal={closeModal}
        />
      }
      <div className="text-left pl-4 pb-4 font-bold text-3xl">
        <h2>Allowance COGM</h2>
      </div>
      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Allowance COGM"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1"></TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="flex flex-wrap text-justify mr-2 ml-2 pb-2">
        {/* <div className="w-full lg:w-9/12 overflow-auto"> */}
        <div className="w-full overflow-auto">
          <Box marginRight={"2px"}>
            <Box height="80vh">
              <StripedDataGrid
                // loading={isLoading || !data}
                getRowId={(row) => row.id}
                columns={columns}
                rowCount={(limit && total) || 0}
                rowsPerPageOptions={[20, 50, 100]}
                rows={data || []}
                pagination
                paginationMode="server"
                sortingMode="server"
                page={page}
                pageSize={limit}
                onPageSizeChange={(newPageSize) => (
                  setlimit(newPageSize), setLoading(true)
                )}
                sortModel={
                  order.length > 0
                    ? [
                        {
                          field: dataHeader[order[0].column],
                          sort: order[0].dir,
                        },
                      ]
                    : []
                }
                onPageChange={(newPage) => (setPage(newPage), setLoading(true))}
                onSortModelChange={(newSortModel) =>
                  setDataOrder(...newSortModel)
                }
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                components={{ Toolbar: DataGridCustomToolbar }}
                componentsProps={{
                  toolbar: { searchInput, setSearchInput, setSearch },
                }}
              />
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default Allowance;
