import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers";
// import { api_data } from "../services/data.service";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
