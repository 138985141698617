import WelcomeBanner from "../components/WelcomeBanner";
import Datepicker from "../components/actions/Datepicker";
const Dashboard = () => {
  return (
    <div className="p-4 ">
      <WelcomeBanner />
      <div className="flex items-center justify-items-start">
        <Datepicker />
      </div>
    </div>
  );
};
export default Dashboard;
