import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  IconButton,
  FormHelperText,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useState } from "react";

const ChangeCalStatus = (props) => {
  const [values, setValues] = useState({
    status: "",
  });
  const [hasError, sethasError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const resetValues = () => {
    props.closeModal();
    sethasError(false);
    setValues({ status: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sethasError(false);
    if (values.status === "") {
      sethasError(true);
    } else {
      //   props.confirmUpdateStatus(props.bom_id, values.status);
      props.confirmStatus(props.calculation_id, values.status);

      setValues({ status: "" });
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={resetValues}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Change Calculation Status
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={resetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form onSubmit={(e) => handleSubmit(e)}>
        <DialogContent dividers>
          <DialogContentText>
            Change this Material {props.SKU ? props.SKU : ""} Calculation Status
            in The fields below:
          </DialogContentText>
          <FormControl sx={{ m: 1, minWidth: 120 }} error={hasError}>
            <InputLabel htmlFor="status">Status</InputLabel>
            <Select
              input={<Input id="status" />}
              name="status"
              value={values.status}
              onChange={handleChange}
            >
              <MenuItem value="02">"02"</MenuItem>
              <MenuItem value="00">"00"</MenuItem>
            </Select>
            {hasError && <FormHelperText>This is required!</FormHelperText>}
          </FormControl>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              endIcon={<PublishedWithChangesIcon />}
            >
              Update
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};
export default ChangeCalStatus;
