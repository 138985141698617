export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SET_STATUS = "SET_STATUS";
export const CLEAR_STATUS = "CLEAR_STATUS";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const DELETE_DATA_SUCCESS = "DELETE_DATA_SUCCESS";
export const DELETE_DATA_FAILED = "DELETE_DATA_FAILED";
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS";
export const UPDATE_DATA_FAILED = "UPDATE_DATA_FAILED";
export const OPEN_DIALOG_BOM = "OPEN_DIALOG_BOM";
export const CLOSE_DIALOG_BOM = "CLOSE_DIALOG_BOM";
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS";
export const GET_MATERIALS_FAILED = "GET_MATERIALS_FAILED";
