import { Box } from "@mui/material";
import { DataGrid, gridClasses, GridActionsCellItem } from "@mui/x-data-grid";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeIcon from "@mui/icons-material/Mode";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CircularProgress from "@mui/material/CircularProgress";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import React, { useState, useEffect } from "react";
import BackupIcon from "@mui/icons-material/Backup";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataGridCustomToolbar from "../../components/DataGridCustomToolbar";
import { alpha, styled } from "@mui/material/styles";
import api from "../../configs/api";
import { UploadFile } from "../../components/Modals";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import {
  deleteData,
  updateQty,
  updateStatus,
  getMaterials,
} from "../../actions/data";
import {
  ConfirmModal,
  ChangeBomQty,
  ChangeBomStatus,
  AddComponent,
} from "../../components/Modals";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { useDispatch } from "react-redux";

const BOM = () => {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  let initialStates = {
    id: "1",
    material: "1212",
    bom_id: "1212",
    qty_material: "0",
    uom_material: "0",
  };
  const [loading, setLoading] = useState(true);
  const [SKU, setSKU] = useState(initialStates);
  const [openDelete, setOpenDelete] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openDialogBOM, setOpenDialogBOM] = useState(false);
  const [openDialogStatus, setOpenDialogStatus] = useState(false);
  const [openDialogAddComponent, setOpenDialogAddComponent] = useState(false);
  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(20);
  const [order, setOrder] = useState([
    {
      column: "0",
      dir: "asc",
    },
    {
      column: "2",
      dir: "asc",
    },
  ]);
  const [search, setSearch] = useState({
    value: "",
  });

  const [value, setValue] = React.useState("1");

  const [data, setData] = useState([]);
  const [status, setStatus] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    const fetchData = async () => {
      try {
        const { data } = await api.post(
          "/api/bom/list",
          { page, limit, order, search },
          {
            signal: abortController.signal,
          }
        );

        if (data) {
          setData(data.data.data);
          setTotal(data.data.recordsFiltered);
          setStatus(data.status);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setData([]);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setData([]);

          setLoading(false);
        }
      }
    };
    fetchData();
    return () => abortController.abort();
  }, [page, limit, order, search]);

  const setDataOrder = React.useCallback((order) => {
    const dataHeader = [
      "sku",
      "sku_name",
      "plant",
      "base_qty",
      "item",
      "uom_sku",
      "material",
      "material_name",
      "qty_material",
      "uom_material",
      "alt_bom",
      "valid_from",
      "valid_to",
      "status",
    ];
    setLoading(true);
    setOrder([
      {
        column: String(dataHeader.indexOf(order.field)),
        dir: order.sort,
      },
    ]);
  }, []);

  const deleteDataRow = (e) => {
    setSKU({
      id: e.row.sku,
      material: e.row.material_name,
      bom_id: e.row.id,
    });
    setOpenDelete(true);
  };
  const exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const refresh = async (sku) => {
    const abortController = new AbortController();
    setLoading(true);

    try {
      const { data } = await api.post(
        "/api/bom/list",
        {
          page,
          limit,
          order,
          search: {
            value: sku,
          },
        },
        {
          signal: abortController.signal,
        }
      );

      if (data) {
        setData(data.data.data);
        setTotal(data.data.recordsFiltered);
        setStatus(data.status);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setData([]);
      }
    } catch (error) {
      if (!abortController.signal.aborted) {
        setData([]);

        setLoading(false);
      }
    }

    return () => abortController.abort();
  };
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CreateNewFolderIcon />}
          label="Add Component"
          onClick={() => {
            DialogAddComponent(params);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon />}
          label="Delete Component"
          onClick={() => deleteDataRow(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<FormatListNumberedIcon />}
          label="Change QTY BOM"
          onClick={() => {
            openDialogQty(params);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ModeIcon />}
          label="Set Status"
          onClick={() => {
            openDialogStat(params);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 100,
      sortable: false,
    },
    { field: "sku_name", headerName: "SKU NAME", width: 300, sortable: false },
    {
      field: "plant",
      headerName: "Plant",
      width: 80,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    { field: "base_qty", headerName: "Base QTY", width: 100 },
    {
      field: "item",
      headerName: "Item",
      width: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "uom_sku",
      headerName: "UOM SKU",
      width: 100,
      align: "center",
      sortable: false,
    },
    { field: "material", headerName: "Material", width: 150 },
    { field: "material_name", headerName: "Material Name", width: 300 },
    { field: "qty_material", headerName: "Material QTY", width: 100 },
    {
      field: "uom_material",
      headerName: "Material UOM",
      width: 100,
      sortable: false,
    },
    {
      field: "alt_bom",
      headerName: "BOM ALT",
      width: 80,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valid_from",
      headerName: "Valid From",
      width: 210,
      sortable: false,
    },
    { field: "valid_to", headerName: "Valid to", width: 210, sortable: false },
    { field: "status", headerName: "Status", width: 80, sortable: false },
  ];
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[300],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  const closeModal = () => {
    setOpenDelete(false);
    setOpenDialogBOM(false);
    setOpenDialogStatus(false);
    setOpenDialogAddComponent(false);
    setOpenModalUpload(false);
  };
  const openDialogQty = (e) => {
    console.log("dispatch");
    setOpenDialogBOM(true);
    setSKU({
      id: e.row.material,
      material: e.row.material_name,
      bom_id: e.row.id,
      qty_material: e.row.qty_material,
      uom_material: e.row.uom_material,
    });
  };
  const openDialogStat = (e) => {
    setOpenDialogStatus(true);
    setSKU({
      id: e.row.material,
      material: e.row.material_name,
      bom_id: e.row.id,
      qty_material: e.row.qty_material,
      uom_material: e.row.uom_material,
    });
  };
  const { message } = useSelector((state) => state.Data);
  const { materials } = useSelector((state) => state.Data);
  const DialogAddComponent = (e) => {
    if (materials.length > 0 && materials[0].plant === e.row.plant) {
      setOpenDialogAddComponent(true);
      setSKU({
        sku: e.row.sku,
        id: e.row.material,
        material: e.row.material_name,
        bom_id: e.row.id,
        qty_material: e.row.qty_material,
        uom_material: e.row.uom_material,
        plant: e.row.plant,
      });
      return;
    }
    dispatch(getMaterials(e.row.plant))
      .then(() => {
        setOpenDialogAddComponent(true);
        setSKU({
          sku: e.row.sku,
          id: e.row.material,
          material: e.row.material_name,
          bom_id: e.row.id,
          qty_material: e.row.qty_material,
          uom_material: e.row.uom_material,
          plant: e.row.plant,
        });
        console.log("getDataMaterials SUccess");
      })
      .catch((err) => {
        console.log("getDataMaterials Error", message);
      });
  };
  const confirmDelete = (bom_id) => {
    console.log("delete", typeof bom_id);
    dispatch(deleteData(bom_id))
      .then(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: `Material ${SKU.material} Deleted`,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            closeModal();
            setSearch({
              value: "",
            });
          }
        });
      })
      .catch((err) => {
        setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Failed to Delete Data",
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              setSearch({
                value: "",
              });
            }
          });
          closeModal();
        }, 3000);
        setLoading(false);
      });
  };

  const confirmUpdate = (bom_id, qty) => {
    console.log("update", bom_id, qty);
    dispatch(updateQty(bom_id, qty))
      .then(() => {
        closeModal();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: `Material ${SKU.material} Qty Updated`,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setSearch({
              value: "",
            });
          }
        });
      })
      .catch((err) => {
        setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Failed to Update Data",
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              setSearch({
                value: "",
              });
            }
          });
          closeModal();
        }, 3000);
        setLoading(false);
      });
  };
  const confirmStatus = (bom_id, status) => {
    console.log("update", bom_id, status);
    dispatch(updateStatus(bom_id, status))
      .then(() => {
        closeModal();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: `Status of Material ${SKU.material} Updated`,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setSearch({
              value: "",
            });
          }
        });
      })
      .catch((err) => {
        setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Failed to Update Data",
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              setSearch({
                value: "",
              });
            }
          });
          closeModal();
        }, 3000);
        setLoading(false);
      });
  };
  const openModal = () => {
    setOpenModalUpload(true);
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      api
        .post(
          "/api/bom/list",
          {
            page,
            limit: 100000,
            order: [
              {
                column: "0",
                dir: "asc",
              },
            ],
            search: {
              value: "",
            },
          },
          {}
        )
        .then((response) => {
          console.log("response", response.data.data);

          exportToExcel(response.data.data.data, "BOM-Data");
          setLoading(false);
        });
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "failed",
        title: error.response.message,
      });
    }
  };
  return (
    <div className="bg-white">
      {
        <UploadFile
          show={openModalUpload}
          handleClose={closeModal}
          Type="BOM"
          refresh={refresh}
        />
      }
      {
        <ConfirmModal
          SKU={SKU}
          open={openDelete}
          closeModal={closeModal}
          confirmDelete={confirmDelete}
        />
      }
      {
        <ChangeBomQty
          open={openDialogBOM}
          closeModal={closeModal}
          SKU={SKU.id}
          bom_id={SKU.bom_id}
          quantity={SKU.qty_material}
          type={SKU.uom_material}
          confirmUpdate={confirmUpdate}
        />
      }
      {
        <ChangeBomStatus
          open={openDialogStatus}
          closeModal={closeModal}
          SKU={SKU.id}
          bom_id={SKU.bom_id}
          quantity={SKU.qty_material}
          type={SKU.uom_material}
          confirmStatus={confirmStatus}
        />
      }
      {
        <AddComponent
          open={openDialogAddComponent}
          closeModal={closeModal}
          SKU={SKU}
          refresh={refresh}
        />
      }
      <div className="text-left pl-2 pb-2 font-bold text-3xl">
        <h2>Master Data BOM</h2>
        <Button
          disabled={loading}
          onClick={openModal}
          style={{
            borderRadius: 5,
            backgroundColor: `${loading ? "#ccc" : "#428777"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<BackupIcon />}
        >
          Upload BOM
        </Button>
        <Button
          disabled={loading}
          onClick={fetchData}
          style={{
            borderRadius: 5,
            backgroundColor: `${loading ? "#ccc" : "#2D3732"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            marginRight: "10px",
          }}
          variant="outlined"
          endIcon={<CloudDownloadIcon />}
        >
          Export Data
        </Button>
      </div>

      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Raw Data" value="1" style={{ color: "#FF0000" }} />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1"></TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="flex flex-wrap text-justify mr-2 ml-2 pb-2">
        {/* <div className="w-full lg:w-9/12 overflow-auto"> */}
        <div className="w-full overflow-auto">
          <Box marginRight={"2px"}>
            <Box height="80vh">
              <StripedDataGrid
                // loading={isLoading || !data}
                getRowId={(row) => row.id}
                columns={columns}
                rowCount={(limit && total) || 0}
                rowsPerPageOptions={[20, 50, 100]}
                rows={data || []}
                pagination
                loading={loading}
                paginationMode="server"
                sortingMode="server"
                page={page}
                pageSize={limit}
                onPageSizeChange={(newPageSize) => (
                  setlimit(newPageSize), setLoading(true)
                )}
                onPageChange={(newPage) => (setPage(newPage), setLoading(true))}
                onSortModelChange={(newSortModel) =>
                  setDataOrder(...newSortModel)
                }
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                components={{ Toolbar: DataGridCustomToolbar }}
                componentsProps={{
                  toolbar: { searchInput, setSearchInput, setSearch },
                }}
              />
            </Box>
          </Box>
        </div>
        {/* <div className="w-full lg:w-3/12 font-bold text-2xl rounded p-2 bg-gray-50 ">
          <div className="mb-2">
            <h1>Backgrounds Jobs</h1>
          </div>
          {status !== 200 ? (
            <div
              id="alert-additional-content-1"
              className="p-1 mb-1 border  border-red-300 rounded-lg bg-red-50 dark:bg-red-200"
              role="alert"
            >
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 mr-2 text-red-700 dark:text-red-800"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
                </svg>

                <h6 className="text-sm font-medium text-red-900">
                  BOM data failed to run
                  <p className="text-xs text-gray-500">
                    {new Date().toLocaleString() + ""}
                  </p>
                </h6>
              </div>
            </div>
          ) : (
            <div
              id="alert-additional-content-1"
              className={`p-1 mb-1 border ${
                loading
                  ? "border-blue-300 rounded-lg bg-blue-50 dark:bg-blue-200"
                  : " border-green-300 rounded-lg bg-green-50 dark:bg-green-200"
              }`}
              role="alert"
            >
              <div className="flex items-center">
                {loading ? (
                  <CircularProgress size="1.5rem" color="info" />
                ) : (
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 mr-2 text-green-700 dark:text-green-800"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.219,1.688c-4.471,0-8.094,3.623-8.094,8.094s3.623,8.094,8.094,8.094s8.094-3.623,8.094-8.094S14.689,1.688,10.219,1.688 M10.219,17.022c-3.994,0-7.242-3.247-7.242-7.241c0-3.994,3.248-7.242,7.242-7.242c3.994,0,7.241,3.248,7.241,7.242C17.46,13.775,14.213,17.022,10.219,17.022 M15.099,7.03c-0.167-0.167-0.438-0.167-0.604,0.002L9.062,12.48l-2.269-2.277c-0.166-0.167-0.437-0.167-0.603,0c-0.166,0.166-0.168,0.437-0.002,0.603l2.573,2.578c0.079,0.08,0.188,0.125,0.3,0.125s0.222-0.045,0.303-0.125l5.736-5.751C15.268,7.466,15.265,7.196,15.099,7.03"></path>
                  </svg>
                )}

                <span className="sr-only">Info</span>
                <h6
                  className={`text-sm font-medium ${
                    loading ? "text-blue-900" : "text-green-900"
                  }`}
                >
                  {loading
                    ? "BOM data running"
                    : `BOM data success running (Total: ${total})`}

                  <p className="text-xs text-gray-500">
                    {new Date().toLocaleString() + ""}
                  </p>
                </h6>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};
export default BOM;
