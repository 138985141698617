import MaterialReactTable from "material-react-table";

import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { useMemo } from "react";

const TableData = ({ data, columns, visibilities }) => {
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            "&:last-child": {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        overrides: {
          MuiTableCell: {
            root: {
              color: "grey",
              padding: "0px",
            },
          },
        },
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(235,236,236)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },

        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "red", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilters={false}
        enableDensityToggle={false}
        enableStickyHeader
        enableGrouping
        enableClickToCopy
        enableColumnOrdering
        muiTableContainerProps={{
          sx: { maxHeight: "500px", margin: "2px" },
        }}
        getRowId={(row) => row.id}
        muiTableHeadCellProps={{
          sx: {
            borderRight: " solid #e0e0e0",
            alignItems: "center",
            // "& .Mui-TableHeadCell-Content-Labels": {
            //   padding: "0px",
            // },
            "& .MuiBox-root": {
              paddingLeft: "0px",
            },
            backgroundColor: "white",

            borderTop: " solid #e0e0e0",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            borderRight: " solid #e0e0e0",
            borderTop: " solid #e0e0e0",
          },
        }}
        muiToolbarAlertBannerProps={
          data.length === 0
            ? {
                color: "error",
                children: "Error loading data, No data found",
              }
            : undefined
        }
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        state={{
          density: "compact",
        }}
        muiTableBodyProps={{
          sx: {
            //stripe the rows, make odd rows a darker color
            "& td:nth-of-type(odd)": {
              backgroundColor: "#f5f5f5",
            },
          },
        }}
        initialState={{ columnVisibility: visibilities ? visibilities : {} }}
      />
    </ThemeProvider>
  );
};
export default TableData;
